import React from 'react'

import icon from '../img/observerLogo_512.png'

export default function LoadingPageComponent({ isLoading, error }) {
    // Handle the loading state
    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        WebkitTransform: 'translate(-50%, -50%)',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <img
                        src={icon}
                        style={{ maxWidth: '100%', width: '100%' }}
                    />
                    <div>
                        <h1>Realtime.Earth</h1>
                    </div>
                </div>
            </div>
        )
    }
    // Handle the error state
    else if (error) {
        console.error('Error: ', error)

        return (
            <div>
                <h1>Sorry, there was a problem loading the page.</h1>
                <p>{JSON.stringify(error)}</p>
            </div>
        )
    } else {
        return null
    }
}
