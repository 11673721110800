import React from 'react'

import {
    EVENT,
    settings,
    LAST_EVENT,
    CAMERA_IS_OFF,
    BACKUP_ICE_SERVERS,
    CAMERA_IS_MINIMIZED,
    RESOURCE_CACHE_FILE_COUNT,
} from '../../settings.js'

import { onValue } from '../../acequia/firebase.js'

import LocalForage from 'localforage/dist/localforage.js'
import bowser from 'bowser/bundled.js'
import ExecutionEnvironment from 'exenv'

import { config } from '../../config.js'
import { generateGUID } from 'redfish-core/lib/auth/guid.js'

import { DeviceBrowserCompatibilityModal } from '../../components/DeviceBrowserCompatibilityComponent.js'

import { AppMenuWrapperFactory } from '../../components/widgets/AppMenu.js'

import { actionTypes } from '../constants.js'

import { displayMessage } from './notificationActions.js'

export function initSettings() {
    return {
        type: actionTypes.INITIALIZE_SETTINGS,
        payload: {
            EVENT,
            settings,
            LAST_EVENT,
            CAMERA_IS_OFF,
            BACKUP_ICE_SERVERS,
            CAMERA_IS_MINIMIZED,
            RESOURCE_CACHE_FILE_COUNT,
        },
    }
}

export function clearPWA() {
    return { type: actionTypes.PWA_INSTALL_NOT_READY }
}

export function initPWAListener() {
    return (dispatch) => {
        dispatch(clearPWA())
        window.addEventListener('beforeinstallprompt', (e) => {
            dispatch({ type: actionTypes.PWA_INSTALL_READY, payload: e })
        })
    }
}

export function getUIVals() {
    return (dispatch) => {
        return getAllUIVals().then((uivals) => {
            console.log('SETTING UIVALS: ', uivals)
            dispatch({ type: actionTypes.SET_UI_VALS, payload: uivals })
            return uivals
        })
    }
}

export function getBrowser() {
    const browser = bowser.getParser(window.navigator.userAgent).parsedResult
    window.browser = browser // putting this in the global context because the app needs it right away
    return {
        type: actionTypes.GET_BROWSER,
        payload: browser,
    }
}

export function checkBrowser() {
    return (dispatch, getState) => {
        let { browser: browser, os: os } = getState().ui.browser
        let condition = os.name === 'iOS' && browser.name !== 'Safari'
        condition =
            condition ||
            (os.name !== 'iOS' &&
                browser.name !== 'Chrome' &&
                browser.name !== 'Chromium')
        if (condition) {
            dispatch(
                showModal(
                    DeviceBrowserCompatibilityModal,
                    'device-browser-compatibility'
                )
            )
        }
    }
}

export function setExecutionEnvironment() {
    return {
        type: actionTypes.SET_EXECUTION_ENVIRONMENT,
        payload: ExecutionEnvironment,
    }
}

export function setUIVal(field, value) {
    return (dispatch) =>
        storeUIVal(field, value).then(() => {
            console.log('storeUIVal :', { field, value })
            dispatch({
                type: actionTypes.UPDATE_UI_VAL,
                payload: { field, value },
            })
        })
}

export function verifyUIValExists(field, defaultValue = false) {
    return new Promise((resolve, reject) => {
        UIStore.getItem(field).then((val) => {
            if (val === null) {
                console.log('creating ', field, defaultValue)
                // not created yet
                storeUIVal(field, defaultValue)
                    .then(() => {
                        resolve(defaultValue)
                    })
                    .catch((e) => reject(e))
            } else {
                resolve(val)
            }
        })
    })
}

export function setGlobal(name, value) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_GLOBAL,
            payload: { name, value },
        })
    }
}

function storeUIVal(field, value) {
    return UIStore.setItem(field, value)
}

export const UIStore = LocalForage.createInstance({
    name: 'UIStore',
})

export function getAllUIVals() {
    const uivals = {}
    return UIStore.iterate(function (value, key) {
        uivals[key] = value
    }).then(() => uivals)
}

export function setPictureInPicture(pictureInPicture, showCamera = true) {
    return {
        type: actionTypes.SET_PICTURE_IN_PICTURE,
        payload: { pictureInPicture, showCamera },
    }
}

export function setFullscreenEnabled(isFullscreenEnabled) {
    return {
        type: actionTypes.SET_FULL_SCREEN_ENABLED,
        payload: isFullscreenEnabled,
    }
}

export function setUILayerActive(isLayerActive) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_UI_LAYER_ACTIVE,
            payload: isLayerActive,
        })
    }
}

export function toggleUILayerActive() {
    return (dispatch, getState) => {
        const components = getState().ui.components
        const isUIComponentActive =
            components &&
            Object.keys(components).reduce(
                (prev, key) => prev || components[key],
                false
            )

        if (isUIComponentActive) {
            for (var key in components) {
                if (components[key]) {
                    dispatch(toggleUIComponentActive(key))
                }
            }
        }
    }
}

export function setUILayerEnabled(isLayerActive) {
    return { type: actionTypes.SET_UI_LAYER_ENABLED, payload: isLayerActive }
}

export function setUILayerSuppressed(isLayerActive) {
    return { type: actionTypes.SET_UI_LAYER_SUPPRESSED, payload: isLayerActive }
}

export function toggleUILayerEnabled() {
    // If the layer is active then first cause it to be not active
    // otherwise flip the state of the enabled
    return (dispatch, getState) => {
        const isActive = getState().ui.uiLayerActive
        if (isActive) {
            dispatch(toggleUILayerActive())
        } else {
            dispatch(setUILayerEnabled(!getState().ui.uiLayerEnabled))
        }
    }
}

export function setUIComponentActive(componentName, isActive) {
    return {
        type: actionTypes.SET_UI_COMPONENT_ACTIVE,
        payload: { componentName, isActive },
    }
}

export function toggleUIComponentActive(componentName) {
    return (dispatch, getState) => {
        dispatch(
            setUIComponentActive(
                componentName,
                !(
                    getState().ui.components &&
                    getState().ui.components[componentName]
                )
            )
        )
    }
}

export function showModal(modalType, name = 'unknown_modal') {
    return { type: 'SHOW_MODAL', payload: { modal: modalType, name } }
}

export function hideModal(modalType = null) {
    return { type: 'HIDE_MODAL', payload: { modal: modalType } }
}

export function errorAction(error) {
    return (dispatch) => {
        console.error('Error: ', error)
        displayMessage(dispatch, `${error}`, { variant: 'error' })
        return { type: actionTypes.ERROR, payload: error }
    }
}

export function removeErrorAction(errorId) {
    return { type: actionTypes.REMOVE_ERROR, payload: errorId }
}

export function addMenuAction(menu) {
    return (dispatch, getState) => {
        const priority =
            menu.props.priority || (getState().ui.menus || []).length
        const key = menu.props.key || generateGUID()
        const AppMenu = AppMenuWrapperFactory(menu, key, priority)
        dispatch({
            type: actionTypes.ADD_MENU,
            payload: <AppMenu key={key} priority={priority} />,
        })
        return { key, priority }
    }
}

export function removeMenuAction(menu) {
    return { type: actionTypes.REMOVE_MENU, payload: menu }
}

export function getCurrentBuild() {
    return (dispatch, getState) => {
        const { build_branch, build_number, build_time } = config
        const configBuildInfo = { build_branch, build_number, build_time }
        const { buildInfoRef } = getState().acequia.firebaseRefs

        // dispatch default
        dispatch({
            type: actionTypes.UPDATE_BUILD_INFO,
            payload: { ...configBuildInfo, latestBuild: null },
        })

        if (build_branch !== null) {
            onValue(buildInfoRef, (snapshot) => {
                const buildInfo = snapshot.val()
                dispatch({
                    type: actionTypes.UPDATE_BUILD_INFO,
                    payload: { ...configBuildInfo, ...buildInfo },
                })
            })
        }
    }
}

export function setMapModeEnabled(val) {
    return { type: actionTypes.SET_MAP_MODE_ENABLED, payload: val }
}

export function setMapMode(mode) {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_MAP_MODE, payload: mode })
    }
}

export function setWindowBounds(width, height) {
    return { type: actionTypes.SET_WINDOW_BOUNDS, payload: { width, height } }
}

export function setAppContentEl(el) {
    return (dispatch, getState) => {
        if (el && getState().ui && getState().ui.appContentEl !== el) {
            dispatch({ type: actionTypes.SET_APP_CONTENT_EL, payload: { el } })
        }
    }
}

export function setMapFeatureIndex(index = 0) {
    return (dispatch, getState) => {
        const uiState = getState().ui
        let geoUI = uiState && uiState.geo
        if (geoUI) {
            dispatch({
                type: actionTypes.UPDATE_UI_VAL,
                payload: {
                    field: 'mapFeatureIndex',
                    value: index,
                },
            })
        }
    }
}
