import { actionTypes } from './constants.js'

import { Interval } from '../interval.js'

const reducer = (
    state = {
        live: false,
        speed: 1,
        tick: 0,
        time: Date.now(),
        zoom: 1,
        playing: false,
        minTime: Date.now() - 1000 * 60 * 60 * 24 * 7,
        maxTime: Date.now() + 1000 * 60 * 60 * 24 * 7,
        timeDelta: 60 * 60 * 1000,
        intervals: {
            lnuLightningComplex: new Interval({
                guid: 'lnuLightningComplex',
                name: 'LNU Complex Timespan',
                type: 'Time',
                endTime: 1599199200000,
                startTime: 1597644000000,
            }),
        },
        showTimeSlider: false,
        tickListeners: {},
        timeListeners: {},
        intervalListeners: {},
    },
    action
) => {
    switch (action.type) {
        case actionTypes.INIT_ANIMATOR: {
            if (state.animator) {
                state.animator.destroy()
            }
            const { animator } = action
            return { ...state, animator }
        }
        case actionTypes.LIVE: {
            state.animator.start()
            return { ...state, live: true, playing: true }
        }
        case actionTypes.START: {
            state.animator.start()
            return { ...state, live: false, playing: true }
        }
        case actionTypes.STOP: {
            state.animator.stop()
            return { ...state, live: false, playing: false }
        }
        case actionTypes.ZOOM: {
            return { ...state, zoom: action.payload }
        }
        case actionTypes.SET_TIME: {
            const minTime =
                action.time < state.minTime ? action.time : state.minTime
            const maxTime =
                action.time > state.maxTime ? action.time : state.maxTime

            return {
                ...state,
                time: action.time,
                minTime,
                maxTime,
            }
        }
        case actionTypes.ADVANCE_TIME: {
            const newTime = Math.max(
                state.minTime,
                Math.min(
                    Date.now(),
                    Math.min(state.maxTime, state.time + action.payload)
                )
            )

            let playing = state.playing
            if (newTime == state.maxTime) {
                state.animator.stop()
                playing = false
            }

            return {
                ...state,
                time: newTime,
                playing,
            }
        }
        case actionTypes.GO_TO_START: {
            return { ...state, time: state.minTime }
        }
        case actionTypes.GO_TO_END: {
            return { ...state, time: state.maxTime, playing: false }
        }
        case actionTypes.STEP_FORWARD: {
            return { ...state, time: state.time + state.timeDelta }
        }
        case actionTypes.STEP_BACKWARD: {
            return { ...state, time: state.time - state.timeDelta }
        }
        case actionTypes.SET_MIN_TIME: {
            const time = state.time < action.time ? action.time : state.time
            return { ...state, minTime: action.time, time }
        }
        case actionTypes.SET_MAX_TIME: {
            const time = state.time > action.time ? action.time : state.time
            return { ...state, maxTime: action.time, time }
        }
        case actionTypes.TICK: {
            return { ...state, tick: state.tick + 1 }
        }
        case actionTypes.FASTER: {
            return { ...state, speed: state.speed * 2 }
        }
        case actionTypes.SLOWER: {
            return { ...state, speed: state.speed / 2 }
        }
        case actionTypes.SPEED: {
            return { ...state, speed: action.payload }
        }
        case actionTypes.SHOW_TIME_SLIDER: {
            return { ...state, showTimeSlider: action.payload }
        }
        case actionTypes.ADD_TIME_INTERVAL: {
            const interval = action.payload
            const intervals = { ...state.intervals, [interval.guid]: interval }
            return { ...state, intervals }
        }
        case actionTypes.SET_TIME_INTERVALS:
            return { ...state, intervals: { ...action.payload } }
        case actionTypes.ADD_TICK_LISTENER: {
            const { guid, listener } = action.payload
            const { tickListeners } = state
            return {
                ...state,
                tickListeners: { ...tickListeners, [guid]: listener },
            }
        }
        case actionTypes.REMOVE_TICK_LISTENER: {
            const newTickListeners = { ...state.tickListeners }
            delete newTickListeners[action.guid]
            return { ...state, tickListeners: newTickListeners }
        }
        case actionTypes.ADD_TIME_LISTENER: {
            const { guid, listener } = action.payload
            const { timeListeners } = state
            return {
                ...state,
                timeListeners: { ...timeListeners, [guid]: listener },
            }
        }
        case actionTypes.REMOVE_TIME_LISTENER: {
            const newTimeListeners = { ...state.timeListeners }
            delete newTimeListeners[action.guid]
            return { ...state, timeListeners: newTimeListeners }
        }
        case actionTypes.ADD_INTERVAL_LISTENER: {
            const { guid, listener } = action.payload
            const { intervalListeners } = state
            return {
                ...state,
                intervalListeners: { ...intervalListeners, [guid]: listener },
            }
        }
        case actionTypes.REMOVE_INTERVAL_LISTENER: {
            const newIntervalListeners = { ...state.intervalListeners }
            delete newIntervalListeners[action.guid]
            return { ...state, intervalListeners: newIntervalListeners }
        }

        // Handle other actions here
        default:
            return state
    }
}

export default reducer
