import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { showModal, hideModal } from '../redux/actions/index.js'

import ModalRoot from './modals/ModalRoot.js'

import Error from './Error.js'
import { modalWrapper } from './modals/ModalUtils.js'

export class ErrorBoundaryUnconnected extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidCatch(error, info) {
        console.error(error)
        this.props.showModal(
            modalWrapper(<Error info={info} error={error} />),
            'error'
        )
        this.setState({ hasError: true })
    }

    render() {
        if (this.state.hasError) {
            return (
                <ModalRoot
                    modals={this.props.modals}
                    onRequestClose={() => {
                        //try to ignore...
                        this.setState({ hasError: false })
                        this.props.hideModal()
                    }}
                    appElement={document.getElementById('root')}
                />
            )
        }

        return this.props.children
    }
}

ErrorBoundaryUnconnected.propTypes = {
    children: PropTypes.object,
    showModal: PropTypes.func,
}

function mapStateToProps(state) {
    return { modals: state.ui.modals }
}

function mapDispatchToProps(dispatch) {
    return {
        showModal: (modal, name) => dispatch(showModal(modal, name)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorBoundaryUnconnected)
