import { actionTypes } from './constants.js'

export default function annotatorReducer(
    state = {
        modes: {},
        colors: { 1: '#FF0000', 2: '#C40080', 3: '#7000BF', 4: '#0004FC' },
        cameras: {},
        incident: {},
        paneOrder: [
            '53f7f2357114e20669bb18b33600',
            //'fd743d611b0c5fc678bf37acad2b',
            //'9714a03719bdf6fdb191d5f8af83',
            //'9b33e372a2aa8782bd3267408cb1',
        ],
        tiePoints: {
            annotatorDS_tiePoint: {
                points: [
                    {
                        cameraID: 'any',
                        vertexID: 'annotatorDS_vertex',
                        timeStamp: 'now',
                        annotationID: 'annotatorDS_annotation',
                    },
                ],
                layerID: 'annotatorDS_layer',
                fillColor: '#FF0000',
                strokeColor: '#FF0000',
                strokeWidth: 2,
            },
        },
        mapCenters: {},
        timeStamps: {},
        addCameras: {},
        annotations: {
            annotatorDS_annotation: {
                guid: 'annotatorDS_annotation',
                type: 'point',
                cameraID: 'any',
                vertices: [
                    {
                        x: 0.5,
                        y: 0.5,
                        guid: 'annotatorDS_vertex',
                        tguid: 'annotatorDS_tiePoint',
                    },
                ],
                timeStamp: 'now',
            },
        },
        addTiePoints: {},
        latLonPoints: {},
        hotCursorJson: { type: 'FeatureCollection', features: [] },
        instanceGuids: [
            '53f7f2357114e20669bb18b33600',
            'fd743d611b0c5fc678bf37acad2b',
            '9714a03719bdf6fdb191d5f8af83',
            '9b33e372a2aa8782bd3267408cb1',
        ],
        incidentNames: {},
        annotationMode: 'add',
        annotationType: 'point',
        activeCameraIDs: {},
    },
    action
) {
    switch (action.type) {
        case actionTypes.MAPS:
            return { ...state, maps: { ...action.payload } }
        case actionTypes.MODES:
            return { ...state, modes: { ...action.payload } }
        case actionTypes.COLORS:
            return { ...state, colors: { ...action.payload } }
        case actionTypes.LABELS:
            return { ...state, labels: { ...action.payload } }
        case actionTypes.LOADED:
            return { ...state, loaded: action.payload }
        case actionTypes.LOADING:
            return { ...state, loading: action.payload }
        case actionTypes.CAMERAS: {
            return { ...state, cameras: { ...action.payload } }
        }
        case actionTypes.ELEMENTS:
            return { ...state, elements: { ...action.payload } }
        case actionTypes.INCIDENT:
            return { ...state, incident: { ...action.payload } }
        case actionTypes.GEO_POINTS:
            return { ...state, geoPoints: { ...action.payload } }
        case actionTypes.TIE_POINTS:
            return { ...state, tiePoints: { ...action.payload } }
        case actionTypes.FULL_STATE:
            return { ...state, ...action.payload }
        case actionTypes.PANE_ORDER:
            return { ...state, paneOrder: [...action.payload] }
        case actionTypes.ADD_CAMERAS:
            return { ...state, addCameras: { ...action.payload } }
        case actionTypes.ANNOTATIONS:
            return { ...state, annotations: { ...action.payload } }
        case actionTypes.INCIDENT_ID:
            return { ...state, incidentID: action.payload }
        case actionTypes.MAP_CENTERS:
            return { ...state, mapCenters: { ...action.payload } }
        case actionTypes.STAR_POINTS:
            return { ...state, starPoints: { ...action.payload } }
        case actionTypes.TIME_STAMPS:
            return { ...state, timeStamps: { ...action.payload } }
        case actionTypes.ACTIVE_POINT:
            return { ...state, activePoint: action.payload }
        case actionTypes.CALIBRATIONS:
            return { ...state, calibrations: { ...action.payload } }
        case actionTypes.INCIDENT_IDS:
            return { ...state, incidentIDs: [...action.payload] }
        case actionTypes.ACTIVE_VERTEX:
            return { ...state, activeVertex: action.payload }
        case actionTypes.PLANET_POINTS:
            return { ...state, planetPoints: { ...action.payload } }
        case actionTypes.ADD_TIE_POINTS:
            return { ...state, addTiePoints: { ...action.payload } }
        case actionTypes.INCIDENT_NAMES:
            return { ...state, incidentNames: { ...action.payload } }
        case actionTypes.LAT_LON_POINTS:
            return { ...state, latLonPoints: { ...action.payload } }
        case actionTypes.GENERATING_MAP:
            return { ...state, generatingMap: action.payload }
        case actionTypes.ANNOTATING_MODE:
            return { ...state, annotatingMode: action.payload }
        case actionTypes.HOT_CURSOR_JSON:
            return { ...state, hotCursorJson: { ...action.payload } }
        case actionTypes.ANNOTATION_MODE:
            return { ...state, annotationMode: action.payload }
        case actionTypes.ANNOTATION_TYPE:
            return { ...state, annotationType: action.payload }
        case actionTypes.ANNOTATION_LAYER:
            return { ...state, annotationLayer: action.payload }
        case actionTypes.ACTIVE_TIE_POINT:
            return { ...state, activeTiePoint: action.payload }
        case actionTypes.ACTIVE_ANNOTATION:
            return { ...state, activeAnnotation: action.payload }
        case actionTypes.ACTIVE_CAMERA_IDS:
            return { ...state, activeCameraIDs: { ...action.payload } }
        case actionTypes.CURRENT_TIE_POINTS:
            return { ...state, currentTiePoints: { ...action.payload } }
        case actionTypes.CURRENT_ACTIVE_INDEX:
            return { ...state, currentActiveIndex: action.payload }
        case actionTypes.CURRENT_CALIBRATIONS:
            return { ...state, currentCalibrations: { ...action.payload } }
        case actionTypes.SEARCH_TERM:
            return { ...state, searchTerm: action.payload }
        case actionTypes.INIT_SEARCH_RESPONSE:
            return { ...state, searchResults: [] }
        case actionTypes.GOT_SEARCH_RESPONSE: {
            const feats = action.payload || []
            const results = [].concat(state.searchResults).concat(feats)
            // make sure camera always come up first in search
            //  should this code be in the Search box element instead.
            const sortIndex = {
                coordinates: 1,
                camera: 2,
                location: 5,
                undefined: 7,
            }
            const resultsSorted = results.sort((a, b) => {
                const ia = sortIndex[a.properties.searchType]
                const ib = sortIndex[b.properties.searchType]
                return ia - ib
            })
            return {
                ...state,
                searchResults: resultsSorted,
            }
        }
        default:
            return state
    }
}
