import { actionTypes } from './constants.js'

let locationPermissionResolver = null

const locationPermissionPromise = new Promise((resolve) => {
    locationPermissionResolver = resolve

    navigator.permissions
        .query({
            name: 'geolocation',
        })
        .then((locationPermission) => {
            if (locationPermission.state == 'granted') {
                resolve(true)
            }
        })
})

let videoPermissionResolver = null

const videoPermissionPromise = new Promise((resolve) => {
    videoPermissionResolver = resolve

    navigator.permissions
        .query({
            name: 'camera',
        })
        .then((videoPermission) => {
            if (videoPermission.state == 'granted') {
                resolve(true)
            }
        })
})

const defaultDeviceSettings = {
    lastUpdated: 10,
    mediaSyncRate: 60000,
    cameraIsMinimized: true,
    BROADCAST: true,
    LOCAL_ORIENTATION: true,
    showNotifications: false,
    maxRecordingTimeMS: 60000,
}

export default function acequiaReducer(
    state = {
        deviceSettings: defaultDeviceSettings,
        locationPermissionPromise,
        locationPermissionResolver: (res) => locationPermissionResolver?.(res),
        videoPermissionPromise,
        videoPermissionResolver: (res) => videoPermissionResolver?.(res),
    },
    action
) {
    switch (action.type) {
        case actionTypes.RECEIVE_FIREBASE_REFS:
            return { ...state, firebaseRefs: action.payload }
        case actionTypes.RECEIVE_MY_LAYER_PERMISSIONS:
            return { ...state, myLayers: action.payload }
        case actionTypes.GET_NEW_LAYER_REF: {
            const myLayers = {
                ...state.myLayers,
                [action.payload.guid]: action.payload.newLayerRef,
            }
            return { ...state, myLayers }
        }
        case actionTypes.RECEIVE_DEVICE_SETTINGS:
            return {
                ...state,
                deviceSettings: { ...defaultDeviceSettings, ...action.payload },
            }
        case actionTypes.TASK_SUBMITTED:
            return { ...state, tasks: { ...state.tasks, ...action.payload } }
        case actionTypes.TASK_COMPLETED:
            return { ...state, tasks: { ...state.tasks, ...action.payload } }
        case actionTypes.TASK_ERRORED:
            return { ...state, tasks: { ...state.tasks, ...action.payload } }
        case actionTypes.UPDATE_BASEURL:
            return { ...state, ...action.payload }
        case actionTypes.RECEIVE_TOKENS: {
            const receivedTokens = state.receivedTokens
                ? [...state.receivedTokens, ...action.payload]
                : [...action.payload]
            return { ...state, receivedTokens }
        }
        case actionTypes.UPDATE_TOKEN: {
            const tokens = {
                ...state.tokens,
                [action.payload.tokenID]: action.payload,
            }
            return { ...state, tokens }
        }
        case actionTypes.SET_USER_READY: {
            return { ...state, userReady: action.payload }
        }
        default:
            return state
    }
}
