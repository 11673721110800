import { actionTypes } from './constants.js'

export default function calibratorReducer(
    state = { rd: false, fov: false, cant: true, calibrationMode: 'star' },
    action
) {
    switch (action.type) {
        case actionTypes.RD_FLAG:
            return { ...state, rd: action.payload }
        case actionTypes.FOV_FLAG:
            return { ...state, fov: action.payload }
        case actionTypes.CANT_FLAG:
            return { ...state, cant: action.payload }
        case actionTypes.FLY_KEYS_MODE:
            return { ...state, flyKeysMode: action.payload }
        case actionTypes.CALIBRATION_MODE:
            return { ...state, calibrationMode: action.payload }
        case actionTypes.CALIBRATOR_INSTANCE_GUID:
            return { ...state, calibratorInstanceGuid: action.payload }
        default:
            return state
    }
}
