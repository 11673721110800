import { keyMirror } from 'redfish-core/lib/utils/utils'

export const actionTypes = keyMirror([
    'RD_FLAG',
    'FOV_FLAG',
    'CANT_FLAG',
    'FLY_KEYS_MODE',
    'CALIBRATION_MODE',
    'CALIBRATOR_INSTANCE_GUID',
])
