import React from 'react'
import { useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import { showModal } from '../../redux/actions/index.js'

import { menuStyle } from '../../Theme.js'
import { modalWrapper } from '../modals/ModalUtils.js'
import { Paper, Grid } from '@material-ui/core'

function PriorityWrapper(props) {
    return <div className="menu-wrapper">{props.children}</div>
}

export function AppMenuWrapperFactory(component, key, priority) {
    return function AppMenuWrapper(props) {
        return (
            <PriorityWrapper priority={priority} key={key}>
                {React.cloneElement(component, { ...props })}
            </PriorityWrapper>
        )
    }
}

function Menu(props) {
    const dispatch = useDispatch()

    const renderMenuBody = () => {
        return (
            <Paper>
                <Grid container direction="column">
                    <Grid item className="menu-title">
                        <Grid container direction="row">
                            <Grid item className="menu-title-icon">
                                {props.icon}
                            </Grid>
                            <Grid item className="menu-title-component">
                                {props.titleComponent}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className="menu-body">
                        {props.bodyComponent}
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    if (props.openMenu) {
        return renderMenuBody()
    }

    return (
        <div
            key={props.key}
            className={`menu-icon ${props.disabled ? 'disabled' : ''}`}
            onClick={(e) => {
                e.stopPropagation()
                if (props.disabled) return
                if (props.onClick) return props.onClick()

                //else default action
                dispatch(showModal(modalWrapper(renderMenuBody())))
            }}
            title={props.name}
        >
            {props.icon}
        </div>
    )
}

const MenuConnected = withStyles(menuStyle)(Menu)

export { Menu as AppMenu, MenuConnected as AppMenuConnected }
