import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const scriptTypes = keyMirror([
    'DEFAULT',
    'LAYER_STARTUP',
    'LAYER_SETUP',
    'LAYER_STEP',
    'LAYER_DRAW',
    'LAYER_REQUEST',
    'LAYER_CLEANUP',
])

export const actionTypes = keyMirror([
    'SET_SCRIPTS',
    'GET_SCRIPTS_REQUESTED',
    'GET_SCRIPTS_FAILED',
    'TURN_OFF_SCRIPTS_REQUESTED',
    'SET_ACTIVE_SCRIPTS',
    'CREATE_NEW_ACTIVE_SCRIPT',
    'CANCEL_CURRENT_ACTIVE_SCRIPT',
    'SAVE_CURRENT_ACTIVE_SCRIPT',
    'DELETE_CURRENT_ACTIVE_SCRIPT',
    'SELECT_CURRENT_ACTIVE_SCRIPT',
    'UPDATE_CURRENT_ACTIVE_SCRIPT',
    'RUN_SCRIPT_BY_ID_CALL',
    'RUN_SCRIPT_BY_ID_RESULT',
    'RUN_SCRIPT_RESULT_PENDING',
    'SET_SCRIPT_FIELD',
    'ADD_SCRIPT',
    'REMOVE_SCRIPT',
    'DEPENDENCIES',
])
