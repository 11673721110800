import { actionTypes } from './constants.js'

const reducer = (state = { results: {} }, action) => {
    let script, newState
    switch (action.type) {
        case actionTypes.SET_SCRIPTS:
            return {
                ...state,
                scripts: action.payload,
            }
        case actionTypes.REMOVE_SCRIPT: {
            const scripts = { ...state.scripts }
            delete scripts[action.payload.guid]
            return { ...state, scripts }
        }

        case actionTypes.SET_ACTIVE_SCRIPTS:
            return {
                ...state,
                activeScripts: action.payload,
            }
        case actionTypes.SELECT_CURRENT_ACTIVE_SCRIPT:
            return {
                ...state,
                currentActiveScript: action.payload,
            }
        case actionTypes.CREATE_NEW_ACTIVE_SCRIPT:
            return {
                ...state,
                currentActiveScript: action.payload,
            }
        case actionTypes.CANCEL_CURRENT_ACTIVE_SCRIPT:
            return {
                ...state,
                currentActiveScript: null,
            }
        case actionTypes.SAVE_CURRENT_ACTIVE_SCRIPT:
            script = action.payload
            newState = {
                ...state,
                currentActiveScript: script,
            }
            newState.activeScripts = {
                ...newState.activeScripts,
                [script.id]: script,
            }
            return newState
        case actionTypes.REMOVE_CURRENT_ACTIVE_SCRIPT:
            script = action.payload
            newState = {
                ...state,
                currentActiveScript: null,
            }
            newState.activeScripts = { ...newState.activeScripts }
            delete newState.activeScripts[script.id]
            return newState
        case actionTypes.UPDATE_CURRENT_ACTIVE_SCRIPT: {
            let { field, value } = action.payload
            newState = { ...state }
            newState.currentActiveScript = {
                ...newState.currentActiveScript,
                [field]: value,
            }
            return newState
        }

        case actionTypes.SET_SCRIPT_FIELD: {
            const { guid, key, val } = action.payload
            const scripts = { ...state.scripts }
            const script = scripts[guid]
            scripts[guid] = { ...script, [key]: val }
            return { ...state, scripts }
        }

        case actionTypes.RUN_SCRIPT_RESULT_PENDING: {
            const { scriptID, runGUID } = action.payload

            let scriptResults = {
                ...state.results[scriptID],
                [runGUID]: {
                    timeStarted: Date.now(),
                    guid: runGUID,
                    status: 'PENDING',
                },
            }

            return {
                ...state,
                results: {
                    ...state.results,
                    [scriptID]: scriptResults,
                },
            }
        }

        case actionTypes.RUN_SCRIPT_BY_ID_RESULT: {
            const { scriptID, runGUID, result } = action.payload

            let results = { ...state.results[scriptID] }
            let resultKeys = Object.keys(results)
            if (resultKeys.length >= 5) {
                delete results[resultKeys[0]]
            }

            let scriptResults = {
                ...state.results[scriptID],
                [runGUID]: result,
                latest: runGUID,
            }

            let resultObj = {
                ...scriptResults[runGUID],
                timeReceived: Date.now(),
                status: 'RECEIVED',
                guid: runGUID,

                result,
            }
            scriptResults[runGUID] = resultObj

            return {
                ...state,
                results: {
                    ...state.results,
                    [scriptID]: scriptResults,
                },
            }
        }

        case actionTypes.DEPENDENCIES:
            return { ...state, dependencies: { ...action.payload } }

        // Handle other actions here
        default:
            return state
    }
}

export default reducer
