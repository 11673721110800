import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.scss'
import 'redfish-core/lib/utils/goodMorning.js'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './redux/store.js'

import { config } from './config.js'

import { theme } from './Theme.js'

import registerServiceWorker from './registerServiceWorker'

import loadable from '@loadable/component'
import LoadingPageComponent from './components/LoadingPageComponent.js'

import LocalForage from 'localforage/dist/localforage.js'

import { rteConfig } from './acequia/firebaseConfig.js'
import ErrorBoundary from './components/ErrorBoundary'

const App = loadable(() => import('./App.js'), {
    fallback: <LoadingPageComponent />,
})

const LoginPage = loadable(() => import('redfish-core/dist/auth.esm.js'), {
    fallback: <LoadingPageComponent />,
})

// const HeadsUpDisplayTest = loadable(
//     () => import('./camera/HeadsUpDisplayTest.js'),
//     { fallback: <LoadingPageComponent /> }
// )

// Seems to be an issue with using the indexeddb driver on iphone 6/7/8 device
// emulation on chrome desktop,  needs more investigation
LocalForage.config({
    driver: LocalForage.INDEXEDDB,
    name: 'realtime.earth',
})
LocalForage.setDriver(LocalForage.INDEXEDDB)
window.LocalForage = LocalForage //making this conveniently available for now to debug

// if (!localStorage.getItem('debug') && config.debug) {
if (config.debug !== undefined) {
    localStorage.setItem('debug', config.debug)
}

async function render() {
    const container = document.getElementById('root')
    const root = createRoot(container)

    root.render(
        <Provider store={store}>
            <div id="ModalDiv"></div>
            <ErrorBoundary>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <BrowserRouter basename={config.build_root}>
                            <Routes>
                                <Route
                                    path="loginpage"
                                    element={
                                        <LoginPage firebaseConfig={rteConfig} />
                                    }
                                />
                                {/* <Route
                                    path="headsUpDisplayTest"
                                    element={<HeadsUpDisplayTest />}
                                /> */}
                                <Route index path="/*" element={<App />} />
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </LocalizationProvider>
            </ErrorBoundary>
        </Provider>
    )
}

registerServiceWorker()
render()
