import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

class ErrorComponent extends Component {
    render() {
        return (
            <Paper>
                <Typography variant="h4">
                    A possibly unrecoverable error has occurred.
                </Typography>
                <Typography variant="body1">
                    {this.props.error && this.props.error.toString()}
                </Typography>

                <TextField
                    id="outlined-read-only-input"
                    label="Error"
                    multiline
                    fullWidth
                    maxRows="10"
                    value={this.props.info && this.props.info.componentStack}
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="outlined"
                />
            </Paper>
        )
    }
}

export default ErrorComponent
