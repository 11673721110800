import { combineReducers } from 'redux'

import acequiaReducer from '../../acequia/redux/reducer.js'
import adminReducer from '../../admin/redux/reducer.js'
import annotatorReducer from '../../annotator/redux/reducer.js'
import authReducer from '../../auth/redux/reducer.js'
import calibratorReducer from '../../calibrator/redux/reducer.js'
import cameraReducer from '../../camera/redux/reducer.js'
import geoReducer from '../../geo/redux/reducer.js'
import layerReducer from '../../layers/redux/reducer.js'
import locatorReducer from '../../locator/redux/reducer.js'
import mediaReducer from '../../media/redux/reducer.js'
import netReducer from '../../net/redux/reducer.js'
import scriptsReducer from '../../script/redux/reducer.js'
import uiReducer from './uiReducer.js'
import visionReducer from '../../vision/redux/reducer.js'
import timeReducer from '../../time/redux/reducer.js'
import workReducer from '../../work/redux/reducer.js'

import debugReducer from './debugReducer.js'

export default combineReducers({
    acequia: acequiaReducer,
    admin: adminReducer,
    annotator: annotatorReducer,
    auth: authReducer,
    calibrator: calibratorReducer,
    camera: cameraReducer,
    debug: debugReducer,
    geo: geoReducer,
    layers: layerReducer,
    locator: locatorReducer,
    media: mediaReducer,
    net: netReducer,
    scripts: scriptsReducer,
    ui: uiReducer,
    vision: visionReducer,
    time: timeReducer,
    work: workReducer,
})
