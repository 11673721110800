import React from 'react'

import { Icon } from '@material-ui/core'

import {
    Earth,
    ChartTimelineVariantShimmer,
    KeyboardReturn,
    Terrain,
    FormatColorText,
    Square,
    SquareOutline,
    VectorPolygon,
    VectorPolyline,
    Navigation,
    Circle,
    Check,
    Timeline,
    Fire,
    Cloud,
    Lock,
    LockOpen,
    AccountChild,
    Alphabetical,
    Numeric,
    QrcodeScan,
    Satellite,
    Fullscreen,
    DotsHorizontal,
    Overscan,
    Broom,
    Plus,
    PlusCircle,
    WeatherPartlyCloudy,
    Bomb,
    Eraser,
    Minus,
    Brush,
    Projector,
    CrosshairsGps,
    Home,
    CloseBox,
    CheckboxMarkedOutline,
    CheckboxBlankOutline,
    Menu,
    ChevronUp,
    ChevronDown,
    ChevronRight,
    ChevronLeft,
    ArrowRight,
    ArrowDown,
    ArrowLeft,
    ArrowUp,
    OpenInNew,
    AxisArrow,
    RotateOrbit,
    VideoStabilization,
    ArrowAll,
} from 'mdi-material-ui'

const ICONS = {
    maCalibrate: <ChartTimelineVariantShimmer />,

    maReturn: <KeyboardReturn />,
    maPlanet: <Earth />,
    maTerrain: <Terrain />,

    maMove: <ArrowAll />,

    maTextColor: <FormatColorText />,
    maFillColor: <Square />,
    maStrokeColor: <SquareOutline />,

    maLine: <VectorPolyline />,
    maPoint: <Circle />,
    maPolygon: <VectorPolygon />,
    maNavigation: <Navigation />,

    maCheck: <Check />,
    maFrames: <Timeline />,

    maFire: <Fire />,
    maSmoke: <Cloud />,

    maLock: <Lock />,
    maUnlock: <LockOpen />,

    maAccountChild: <AccountChild />,

    maIntrinsics: <VideoStabilization />,

    maNumeric: <Numeric />,
    maAlphabetical: <Alphabetical />,

    maAxis: <AxisArrow />,
    maRotate: <RotateOrbit />,

    maArrowUp: <ArrowUp />,
    maArrowDown: <ArrowDown />,
    maArrowLeft: <ArrowLeft />,
    maArrowRight: <ArrowRight />,

    maCaretUp: <ChevronUp />,
    maCaretDown: <ChevronDown />,
    maCaretRight: <ChevronRight />,
    maCaretLeft: <ChevronLeft />,

    maMenu: <Menu />,
    maCheckbox: <CheckboxBlankOutline />,
    maCheckboxMarked: <CheckboxMarkedOutline />,
    maLocation: <CrosshairsGps />,
    maProjector: <Projector />,
    maSky: <WeatherPartlyCloudy />,
    maPlus: <Plus />,
    maMinus: <Minus />,
    maPlusCircle: <PlusCircle />,
    maQRCodeScan: <QrcodeScan />,
    maSatellite: <Satellite />,
    maMoreHoriz: <DotsHorizontal />,
    maFullscreen: <Fullscreen />,
    maSettingsOverscan: <Overscan />,
    maBroom: <Broom />,
    maBomb: <Bomb />,
    maEraser: <Eraser />,
    maBrush: <Brush />,
    maHome: <Home />,
    maClose: <CloseBox />,
    maExternal: <OpenInNew />,

    maBadgeIcon: <Icon>badge_icon</Icon>,
    maImages: <Icon>burst_mode</Icon>,
    maStar: <Icon>stars</Icon>,
    maSearch: <Icon>search</Icon>,
    maArrowForward: <Icon>arrow_right</Icon>,
    maArrowBackward: <Icon>arrow_left</Icon>,
    maCached: <Icon>cached</Icon>,
    maFileCopy: <Icon>file_copy</Icon>,
    maCodeBraces: <Icon>code_braces</Icon>,
    maZoomOutMap: <Icon>zoom_out_map</Icon>,
    maZoomIn: <Icon>zoom_in</Icon>,
    maList: <Icon>list</Icon>,
    maVideoCall: <Icon>video_call</Icon>,
    maVideoGame: <Icon>speed</Icon>,
    maKeyboard: <Icon>keyboard</Icon>,
    maJob: <Icon>playlist_play</Icon>,
    maSettings: <Icon>settings</Icon>,
    maInfo: <Icon>info</Icon>,
    maNetwork: <Icon>wifi</Icon>,
    maCamera: <Icon>photo_camera</Icon>,
    maLanguage: <Icon>language</Icon>,
    maMap: <Icon>map</Icon>,
    maComputer: <Icon>computer</Icon>,
    maColorLens: <Icon>color_lens</Icon>,
    maDelete: <Icon>delete</Icon>,
    maShare: <Icon>share</Icon>,
    maSend: <Icon>send</Icon>,
    maCode: <Icon>code</Icon>,
    maPeople: <Icon>people</Icon>,
    maCoffee: <Icon>local_cafe</Icon>,
    maSchedule: <Icon>schedule</Icon>,
    maEvent: <Icon>live_tv</Icon>,
    maLayers: <Icon>layers</Icon>,
    maTextureLight: <Icon>videocam</Icon>,
    maTextureTarget: <Icon>games</Icon>,
    maImage: <Icon>image</Icon>,
    maLandscape: <Icon>landscape</Icon>,
    ma3D: <Icon>3d_rotation</Icon>,
    maTiePoints: <Icon>control_point_duplicate</Icon>,
    maPlay: <Icon>play_arrow</Icon>,
    maEdit: <Icon>edit</Icon>,
    maPower: <Icon>power_settings_new</Icon>,
    maRecord: <Icon>fiber_manual_record</Icon>,
    maMinimize: <Icon>arrow_drop_up</Icon>,
    maMaximize: <Icon>arrow_drop_down</Icon>,
    maMedia: <Icon>photo_library</Icon>,
    maPlaylistAddCheck: <Icon>playlist_add_check</Icon>,
    maPrevious: <Icon>skip_previous</Icon>,
    maNext: <Icon>skip_next</Icon>,

    maPause: <Icon>pause</Icon>,
    maUpArrow: <Icon>arrow_upward</Icon>,
    maDownArrow: <Icon>arrow_downward</Icon>,
    maEye: <Icon>visibility</Icon>,
    maEyeSlash: <Icon>visibility_off</Icon>,
    maDownload: <Icon>cloud_download</Icon>,
    maUpload: <Icon>cloud_upload</Icon>,
    maSave: <Icon>save</Icon>,
    maUndo: <Icon>undo</Icon>,
    maRedo: <Icon>redo</Icon>,
    maPinDrop: <Icon>pin_drop</Icon>,
    maFitting: <Icon>compass_calibration</Icon>,
    maCenter: <Icon>filter_center_focus</Icon>,
    maSwitchCamera: <Icon>switch_camera</Icon>,
    maPublic: <Icon>public</Icon>,
    maReplay: <Icon>replay</Icon>,
    maFireplace: <Icon>fireplace</Icon>,
}

const ICON_MAP = {
    name: 'maBadgeIcon',
    images: 'maImages',

    calibrate: 'maCalibrate',
    return: 'maReturn',

    terrain: 'maTerrain',
    planet: 'maPlanet',
    star: 'maStar',

    move: 'maMove',

    textColor: 'maTextColor',
    fillColor: 'maFillColor',
    strokeColor: 'maStrokeColor',

    line: 'maLine',
    point: 'maPoint',
    polygon: 'maPolygon',
    navigation: 'maNavigation',

    search: 'maSearch',

    check: 'maCheck',

    frames: 'maFrames',

    fire: 'maFire',
    smoke: 'maSmoke',

    lock: 'maLock',
    unlock: 'maUnlock',

    copy: 'maFileCopy',
    parent: 'maAccountChild',

    reload: 'maCached',
    zoomIn: 'maZoomIn',
    number: 'maNumeric',
    bounds: 'maZoomOutMap',
    object: 'maCodeBraces',
    string: 'maAlphabetical',

    sky: 'maSky',
    axis: 'maAxis',
    bomb: 'maBomb',
    list: 'maList',
    rotate: 'maRotate',
    keyboard: 'maKeyboard',
    plusVideo: 'maVideoCall',
    projector: 'maProjector',
    intrinsics: 'maIntrinsics',

    arrowUp: 'maArrowUp',
    arrowDown: 'maArrowDown',
    arrowLeft: 'maArrowLeft',
    arrowRight: 'maArrowRight',
    arrowForward: 'maArrowForward',
    arrowBackward: 'maArrowBackward',

    brush: 'maBrush',
    plusCircle: 'maPlusCircle',
    plus: 'maPlus',
    minus: 'maMinus',

    home: 'maHome',
    close: 'maClose',
    menu: 'maMenu',

    location: 'maLocation',
    ar: 'maImage',
    upclose: 'maCaretUp',
    downclose: 'maCaretDown',
    rightclose: 'maCaretRight',
    leftclose: 'maCaretLeft',
    checkbox: 'maCheckbox',
    checkboxMarked: 'maCheckboxMarked',

    clean: 'maBroom',
    satellite: 'maSatellite',
    ellipses: 'maMoreHoriz',
    fullscreen: 'maFullscreen',
    fillWindow: 'maSettingsOverscan',
    job: 'maJob',
    settings: 'maSettings',
    info: 'maInfo',
    camera: 'maCamera',
    network: 'maNetwork',
    globe: 'maLanguage',
    map: 'maMap',
    colorLens: 'maColorLens',
    delete: 'maDelete',
    share: 'maShare',
    plane: 'maSend',
    code: 'maCode',
    script: 'maCode',
    peers: 'maPeople',
    coffee: 'maCoffee',
    clock: 'maSchedule',
    event: 'maEvent',
    layers: 'maLayers',
    textureLight: 'maTextureLight',
    image: 'maImage',
    landscape: 'maLandscape',
    '3D': 'ma3D',
    textureTarget: 'maTextureTarget',
    tiePoints: 'maTiePoints',
    play: 'maPlay',
    edit: 'maEdit',
    power: 'maPower',
    record: 'maRecord',
    minimize: 'maMinimize',
    maximize: 'maMaximize',
    media: 'maMedia',
    playlistAddCheck: 'maPlaylistAddCheck',
    previous: 'maPrevious',
    pause: 'maPause',
    upArrow: 'maUpArrow',
    downArrow: 'maDownArrow',
    next: 'maNext',
    computer: 'maComputer',
    eye: 'maEye',
    eyeSlash: 'maEyeSlash',
    upload: 'maUpload',
    download: 'maDownload',
    save: 'maSave',
    undo: 'maUndo',
    redo: 'maRedo',
    pinDrop: 'maPinDrop',
    fitting: 'maFitting',
    center: 'maCenter',
    switchCamera: 'maSwitchCamera',
    public: 'maPublic',
    replay: 'maReplay',
    retry: 'maReplay',
    QRCodeScan: 'maQRCodeScan',
    eraser: 'maEraser',
    external: 'maExternal',
    fireplace: 'maFireplace',
}

function getIcon(iconName) {
    let _iconName = ICON_MAP[iconName]
    if (!_iconName) {
        _iconName = ICON_MAP['coffee']
    }

    return ICONS[_iconName]
}

export { getIcon }
