export const settings = {
    debugFirebaseHandshakeMessages: false,
    maxPoseZoomToPublish: 13,
    minPoseZoomToPublish: 4,
    CHUNK_SIZE: Math.pow(2, 10) * 16, // 16KB recommended, size in bytes of the chunks. 2^16 is just under the limit in chrome.
    POLLING_FREQUENCY: 15000,
    throttleSpeed: 250, //ms
    mapboxglAccessToken:
        'pk.eyJ1IjoibTBvc2UiLCJhIjoiY2lqd2JzMnM4MDg0M3Z5a2ltY2E0dnptMSJ9.Fv5DsddjYp9xLy2nGk8_Ew',
    defaultDeviceSettings: {
        camera: {
            recordingLength: {
                type: 'integer',
                units: 'seconds',
                default: 60,
            },
        },
    },
    defaults: {
        fx: 1200,
        fy: 1200,
        x0: 0,
        y0: 0,
        s: 0,
        width: 600,
        height: 600,
        near: 0.01,
        far: 100,
    },
    defaultBounds: {
        lat: 35.665375299999994,
        lng: -105.9631708,
        zoom: 13,
        east: -105.91398981548994,
        west: -106.01235178448519,
        north: 35.69291466557347,
        south: 35.63782643159496,
    },
    defaultTextSpriteSize: 0.015,
}

export const BACKUP_ICE_SERVERS = [
    // note this only has stun servers. Turn requires $$$
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:stun1.l.google.com:19302' },
    { urls: 'stun:stun2.l.google.com:19302' },
    { urls: 'stun:stun3.l.google.com:19302' },
    { urls: 'stun:stun4.l.google.com:19302' },
]

export const CAMERA_IS_OFF = 'livetexture:camera_off'
export const CAMERA_IS_MINIMIZED = 'livetexture:camera_is_minimized'

export const LAST_EVENT = 'livetexture:LAST_EVENT'
export const EVENT = 'livetexture:event'

/*export const ELEVATION_TILE_URL =
    'https://s3-us-west-2.amazonaws.com/world-elevation-tiles/DEM_tiles/{z}/{x}/{y}.png'*/

export const RESOURCE_CACHE_FILE_COUNT = 1000
