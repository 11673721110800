import React from 'react'

import { RootModalContext } from './RootModalContext.js'

import Fab from '@material-ui/core/Fab'

import { UISuppressor } from '../utils/UISuppressor.js'
import { getIcon } from '../Icon.js'

import './modals.scss'

function ModalRoot(props = {}) {
    const { modals, ...rest } = props

    if (!modals || modals.length < 1) return null

    const modal = modals[modals.length - 1]

    const showCloseButton = !(
        modal.props.hideCloseButton || props.hideCloseButton
    )

    const closeButtonLabel =
        modal.props.closeButtonLabel ||
        props.closeButtonLabel ||
        getIcon('close')

    
        console.log('modalRoot', modal)

        const onRequestClose =
            modal.props.onRequestClose || props.onRequestClose

    const suppressUI =
        ((modal.props.suppressUI || props.suppressUI) && <UISuppressor />) ||
        null

    return (
        <div
            className="modal-click-container"
            onClick={(e) => e.stopPropagation()}
        >
            <RootModalContext.Provider
                value={{ onRequestClose, appElement: props.appElement }}
            >
                <modal.type
                    className="modal-content"
                    overlayClassName="modal-overlay"
                    {...rest}
                    {...modal.props}
                    parentSelector={() => document.querySelector('#ModalDiv')}
                    isOpen={true}
                >
                    {showCloseButton ? (
                        <Fab
                            id="modal-close-button"
                            data-close
                            aria-label="Close modal"
                            type="button"
                            onClick={onRequestClose}
                        >
                            <span className="bob" aria-hidden="true">
                                {closeButtonLabel}
                            </span>
                        </Fab>
                    ) : null}
                    {suppressUI}
                    {modal.props.children}
                </modal.type>
            </RootModalContext.Provider>
        </div>
    )
}

export default ModalRoot
