import { actionTypes } from '../constants.js'

const initialModals = [],
    initialErrors = [],
    initialMenus = []

const reducer = (
    state = {
        uiLayerEnabled: false,
        geo: { mapModeEnabled: true, mapMode: '2D' },
        notifications: [],
    },
    action
) => {
    switch (action.type) {
        case actionTypes.INIT_APP: {
            return { ...state, ...action.payload }
        }
        case actionTypes.INITIALIZE_SETTINGS: {
            return { ...state, ...action.payload }
        }
        case actionTypes.UPDATE_UI_VAL: {
            let { field, value } = action.payload
            return { ...state, [field]: value }
        }
        case actionTypes.SET_UI_VALS:
            return { ...state, ...action.payload }
        case actionTypes.SET_PICTURE_IN_PICTURE:
            return { ...state, ...action.payload }
        case actionTypes.SET_FULL_SCREEN_ENABLED:
            return { ...state, isFullscreenEnabled: action.payload }
        case actionTypes.SET_GLOBAL:
            return { ...state, [action.payload.name]: action.payload.value }
        case actionTypes.GET_BROWSER:
            return { ...state, browser: action.payload }
        case actionTypes.SET_EXECUTION_ENVIRONMENT:
            return { ...state, executionEnvironment: action.payload }

        case actionTypes.SET_UI_LAYER_ACTIVE:
            return { ...state, uiLayerActive: action.payload }
        case actionTypes.SET_UI_LAYER_ENABLED:
            return { ...state, uiLayerEnabled: action.payload }
        case actionTypes.SET_UI_LAYER_SUPPRESSED:
            return { ...state, uiLayerSuppressed: action.payload }

        case actionTypes.ADD_MENU: {
            const menus = state.menus || initialMenus

            return { ...state, menus: [...menus, action.payload] }
        }

        case actionTypes.REMOVE_MENU: {
            const menus = state.menus || initialMenus
            const menuToRemove = action.payload

            return {
                ...state,
                menus: menus.filter((menu) => {
                    return menu.key !== menuToRemove
                }),
            }
        }

        case actionTypes.SET_UI_COMPONENT_ACTIVE: {
            const components = { ...state.components }
            components[action.payload.componentName] = action.payload.isActive
            if (action.payload.isActive) {
                for (var key in components) {
                    if (
                        components[key] &&
                        key !== action.payload.componentName
                    ) {
                        components[key] = false
                    }
                }
            }
            const uiLayerActive = Object.keys(components).reduce(
                (prev, key) => prev || components[key],
                false
            )
            const uiLayerEnabled = uiLayerActive
            return {
                ...state,
                components,
                uiLayerActive,
                uiLayerEnabled,
            }
        }

        case actionTypes.SHOW_MODAL: {
            const modals = [...(state.modals || initialModals)]

            modals.push(action.payload.modal)
            return { ...state, modals }
        }
        case actionTypes.HIDE_MODAL: {
            const modals = [...(state.modals || initialModals)]

            if (modals.length === 0) return { ...state }

            const { modal } = action.payload

            if (modal) {
                if (modals[modals.length - 1]?.type === modal.type) {
                    console.log('closing modal: ', modal)
                    modals.pop()
                }
            } else {
                console.log('closing modal: unknown')
                modals.pop()
            }

            return { ...state, modals }
        }
        case actionTypes.ERROR: {
            let errors = [...(state.errors || initialErrors)]
            errors.push(action.payload)
            return { ...state, errors }
        }
        case actionTypes.REMOVE_ERROR: {
            let errors = [...(state.errors || initialErrors)]
            const errorId = action.payload
            if (errorId === undefined) {
                errors.pop()
            } else {
                errors.splice(errorId, 1)
            }
            return { ...state, errors }
        }
        case actionTypes.UPDATE_BUILD_INFO: {
            return { ...state, buildInfo: action.payload }
        }
        case actionTypes.PWA_INSTALL_NOT_READY: {
            return { ...state, pwaInstall: null }
        }
        case actionTypes.PWA_INSTALL_READY: {
            return { ...state, pwaInstall: action.payload }
        }
        case actionTypes.SET_MAP_MODE_ENABLED: {
            return {
                ...state,
                geo: { ...state.geo, mapModeEnabled: action.payload },
            }
        }
        case actionTypes.SET_MAP_MODE: {
            return { ...state, geo: { ...state.geo, mapMode: action.payload } }
        }

        case actionTypes.SET_WINDOW_BOUNDS: {
            return { ...state, windowBounds: action.payload }
        }

        case actionTypes.ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            }

        case actionTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            }

        case actionTypes.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.key
                ),
            }

        case actionTypes.SET_APP_CONTENT_EL:
            return {
                ...state,
                appContentEl: action.payload.el,
            }

        default:
            return state
    }
}

export default reducer
