import { keyMirror } from 'redfish-core/lib/utils/utils'

export const actionTypes = keyMirror([
    'AUTH_INIT',
    'AUTH_CHANGE',
    'AUTH_LOGOUT',
    'AUTH_STATECHANGE',
    'AUTH_RECEIVE_REF',
    'AUTH_NO_USER',
    'SET_DISPLAY_NAME',
    'INITIALIZE_ID',
    'UPDATE_USERINFO',
])
