import { generateGUID } from 'redfish-core/lib/auth/guid.js'
import { DateTime } from 'luxon'

export class Interval {
    constructor(options = {}) {
        this.guid = options.guid || generateGUID()
        this.name = options.name
        this.type = options.type
        this.timezone = options.timezone || DateTime.zone
        this.startTime = options.startTime
        this.endTime = options.endTime
    }
}
