import { actionTypes } from './constants.js'

const defaultSettings = {
    currentJobs: {},
}

function removeJob(state, action) {
    const { job } = action.payload
    let { currentJobs } = state
    delete currentJobs[job.guid]
    return {
        ...state,
        currentJobs: { ...currentJobs },
    }
}

export default function acequiaReducer(state = defaultSettings, action) {
    switch (action.type) {
        case actionTypes.START_JOB: {
            const { job } = action.payload
            return {
                ...state,
                currentJobs: { ...state.currentJobs, [job.guid]: job },
            }
        }
        case actionTypes.COMPLETE_JOB:
            return removeJob(state, action)
        case actionTypes.RETRY_JOB:
            return removeJob(state, action)
        case actionTypes.ERROR_JOB:
            return removeJob(state, action)

        default:
            return state
    }
}
