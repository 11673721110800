import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setUILayerSuppressed } from '../../redux/actions/index.js'

class UISuppressorComponent extends Component {
    componentDidMount() {
        this.props.hideUILayer()
    }

    componentWillUnmount() {
        this.props.showUILayer()
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    return {
        uiLayerSuppressed: state.ui && state.ui.uiLayerSuppressed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        hideUILayer: () => dispatch(setUILayerSuppressed(true)),
        showUILayer: () => dispatch(setUILayerSuppressed(false)),
    }
}

export const UISuppressor = connect(
    mapStateToProps,
    mapDispatchToProps
)(UISuppressorComponent)
