import { actionTypes } from './constants.js'

export default function cameraReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.START_QR_SCAN:
            return { ...state, scanning: true }
        case actionTypes.STOP_QR_SCAN:
            return { ...state, scanning: false }
        case actionTypes.QR_SCAN_RESULT:
            return { ...state, scanResult: action.payload }
        default:
            return state
    }
}
