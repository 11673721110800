import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'EVENTS',
    'SAVED_EVENT_PERMISSION',
    'DELETE_TOKEN',
    'POSITION',
    'POSITION_UNDEFINED',
    'GET_LAYER_LIST',
    'LAYER_CHANGE_VALUE',
    'LAYER_CHANGE_VALUE_SAVED',
    'ADD_LAYER_INSTANCE',
    'REMOVE_LAYER_INSTANCE',
    'PUBLISH_LAYER',
    'REMOVE_LAYER',
    'LAYER_ERROR',
    'LAYERS',
    'FADE',
    'FADING',
    'SET_MAP',
    'PAGE_IDS',
    'KEYS_ENABLED',
    'SAVED_EVENTS',
    'EVENT_LOADING',
    'GEOPOINT_MODE',
    'ACTIVE_PAGE_ID',
    'ACTIVE_EVENT_ID',
    'UVRAY_VISIBILITY',
    'LABEL_VISIBILITY',
    'FLY_KEYS_ENABLED',
    'FRUSTUM_VISIBILITY',
    'FITTING_MODAL_OPEN',
    'GEOPOINT_VISIBILITY',
    'KEY_INFO_MODAL_OPEN',
    'PULL_HIGH_RESOLUTION',
    'ANNOTATION_MODAL_OPEN',
    'IMAGE_PLANE_VISIBILITY',
    'GET_MAP_SELECTED_STYLE',
    'SET_MAP_SELECTED_STYLE',
    'SET_GEO_JSON_LAYER_MANAGER',
    'TEXTURELIGHT_HUD_MODAL_OPEN',
    'LAYER_LOADING',
    'LAYER_LOADED',
])
