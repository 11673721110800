// configs

import { config } from '../config.js'

const livetextureConfig = {
    apiKey: 'AIzaSyBI-Js8EBS6pe2GmTG0IGpxgeIbtGJkbIA',
    authDomain: 'livetexture.firebaseapp.com',
    databaseURL: 'https://livetexture.firebaseio.com',
    projectId: 'firebase-livetexture',
    storageBucket: 'firebase-livetexture.appspot.com',
    messagingSenderId: '608999363673',
    appId: '1:608999363673:web:8d2b4735bfc303384bcd48',
}

const hellenesConfig = {
    apiKey: 'AIzaSyBI-Js8EBS6pe2GmTG0IGpxgeIbtGJkbIA',
    authDomain: 'livetexture.firebaseapp.com',
    databaseURL:
        'https://hellenes-alert-live.europe-west1.firebasedatabase.app',
    projectId: 'firebase-livetexture',
    storageBucket: 'firebase-livetexture.appspot.com',
    messagingSenderId: '608999363673',
    appId: '1:608999363673:web:8d2b4735bfc303384bcd48',
}

const anyhazardConfig = {
    apiKey: 'AIzaSyBF64nlSg3eJRyuJDZ_yuySIwaREifymwg',
    databaseURL: 'https://anyhazard.firebaseio.com',
    authDomain: 'anyhazard.firebaseio.com',
    projectId: 'anyHazard',
}

const acequiaConfig = {
    apiKey: 'AIzaSyDZiLOsrcCeYsDe1kGyORPpExVNBwzpmqY',
    databaseURL: 'https://acequia.firebaseio.com',
    authDomain: 'acequia.firebaseio.com',
    projectId: 'firebase-acequia',
    storageBucket: 'gs://firebase-acequia.appspot.com',
}

let rteConfig =
    config.sub_app === 'hellenes' ? hellenesConfig : livetextureConfig

let databaseURL = rteConfig.databaseURL

console.log('databaseURL: ', databaseURL)

export { acequiaConfig, databaseURL, rteConfig, anyhazardConfig }
