import React from 'react'

import { actionTypes } from '../constants.js'

import store from '../store.js'
import Button from '@material-ui/core/Button'
import { Icon } from '@material-ui/core'
import { doNext } from 'redfish-core/lib/utils/utils.js'

export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key

    return {
        type: actionTypes.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    }
}

export const closeSnackbar = (key) => ({
    type: actionTypes.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
})

export const removeSnackbar = (key) => ({
    type: actionTypes.REMOVE_SNACKBAR,
    key,
})

function closeSnackbarButton(key) {
    return (
        <Button
            onClick={() => {
                store.dispatch(closeSnackbar(key))
            }}
        >
            <Icon>close</Icon>
        </Button>
    )
}

export function displayMessage(dispatch = null, message, options) {
    if (!dispatch) dispatch = store.dispatch
    const state = store.getState()
    const showNotifications =
        state &&
        state.acequia &&
        state.acequia.deviceSettings &&
        state.acequia.deviceSettings.showNotifications

    if (!showNotifications) return

    return doNext(() =>
        dispatch(
            enqueueSnackbar({
                message: message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                    action: closeSnackbarButton,
                    autoHideDuration: 1000,
                    preventDuplicate: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    ...options,
                    // action: key => (
                    //     <Button onClick={() => dispatch(closeSnackbar(key))}>
                    //         dissmiss me
                    //     </Button>
                    // ),
                },
            })
        )
    )
}
