import { actionTypes } from './constants.js'

import { types as layerTypes } from '../../geo/layers/layerStore.js'

export default function layerReducer(
    state = {
        layerUI: [],
        layerData: {},
        layerMeta: {
            cameraJson: {
                guid: 'cameraJson',
                show: false,
                type: layerTypes.GEOJSON,
                deletable: false,
                annotatable: false,
            },
            locator_layer: {
                guid: 'locator_layer',
                name: 'locator_layer',
                show: false,
                type: layerTypes.GEOJSON,
                annotatable: true,
            },
            annotatorDS_layer: {
                guid: 'annotatorDS_layer',
                show: false,
                type: layerTypes.GEOJSON,
                deletable: false,
                annotatable: true,
            },
            intersection_layer: {
                guid: 'intersection_layer',
                show: false,
                type: layerTypes.GEOJSON,
                deletable: false,
                annotatable: true,
            },
        },
        layerStyle: {},
        mapLayerIDs: {},
        dataRequests: {},
        tiePointLayer: 'dca4c7e89b77646012bbc8b11992',
        deleteLayerMeta: {},
    },
    action
) {
    switch (action.type) {
        case actionTypes.LOADED:
            return { ...state, loaded: action.payload }
        case actionTypes.LOADING:
            return { ...state, loading: action.payload }
        case actionTypes.LAYER_UI:
            return { ...state, layerUI: [...action.payload] }
        case actionTypes.LAYER_DATA:
            return { ...state, layerData: { ...action.payload } }
        case actionTypes.LAYER_META:
            return { ...state, layerMeta: { ...action.payload } }
        case actionTypes.LAYER_STYLE:
            return { ...state, layerStyle: { ...action.payload } }
        case actionTypes.ACTIVE_LAYER:
            return { ...state, activeLayer: action.payload }
        case actionTypes.DATA_REQUESTS:
            return { ...state, dataRequests: { ...action.payload } }
        case actionTypes.MAP_LAYER_IDS:
            return { ...state, mapLayerIDs: { ...action.payload } }
        case actionTypes.TIE_POINT_LAYER:
            return { ...state, tiePointLayer: action.payload }
        case actionTypes.DELETE_LAYER_META:
            return { ...state, deleteLayerMeta: { ...action.payload } }
        default:
            return state
    }
}
