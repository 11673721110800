import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'LIVE',
    'STOP',
    'TICK',
    'ZOOM',
    'SPEED',
    'START',
    'FASTER',
    'SLOWER',
    'SET_TIME',
    'SET_SPEED',
    'GO_TO_END',
    'GO_TO_START',
    'ADVANCE_TIME',
    'SET_MIN_TIME',
    'SET_MAX_TIME',
    'SET_END_TIME',
    'SET_START_TIME',
    'SHOW_TIME_SLIDER',
    'STEP_FORWARD',
    'STEP_BACKWARD',
    'INIT_ANIMATOR',
    'ADD_TIME_INTERVAL',
    'ADD_TICK_LISTENER',
    'ADD_TIME_LISTENER',
    'SET_TIME_INTERVALS',
    'REMOVE_TICK_LISTENER',
    'REMOVE_TIME_LISTENER',
    'ADD_INTERVAL_LISTENER',
    'REMOVE_INTERVAL_LISTENER',
])
