import { actionTypes } from './constants.js'

export default function authReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.AUTH_INIT:
            return { ...state, ui: action.payload }
        case actionTypes.AUTH_LOGOUT:
            return { ...state, uid: null, user: null }
        case actionTypes.AUTH_NO_USER:
            return {
                ...state,
                uid: null,
                user: null,
                displayName: action.payload.displayName,
            }
        case actionTypes.AUTH_STATECHANGE:
            return { ...state, ...action.payload }
        case actionTypes.AUTH_RECEIVE_REF:
            return { ...state, ...action.payload }
        case actionTypes.SET_DISPLAY_NAME:
            return { ...state, displayName: action.payload }
        case actionTypes.INITIALIZE_ID:
            return { ...state, ...action.payload }
        case actionTypes.UPDATE_USERINFO: {
            const user = action.payload
            const userInfo = { ...state.userInfo }
            userInfo[user.uid] = user
            return { ...state, userInfo }
        }

        default:
            return state
    }
}
