import React from 'react'

import Modal from 'react-modal'

import { RootModalContext } from './RootModalContext.js'

function isElement(element) {
    return React.isValidElement(element)
}

function isDOMTypeElement(element) {
    return (
        (isElement(element) && typeof element.type === 'string') ||
        (typeof element.type === 'object' &&
            element.type.options &&
            element.type.options.name &&
            element.type.options.name.startsWith('Mui'))
    )
}

/*
provides a modal wrapper around a given component.
passed the rootModalContext as a prop to the component.
*/
export function modalWrapper(component, modalProps = {}) {
    return (
        <Modal suppressUI contentLabel="Modal Content" {...modalProps}>
            {isDOMTypeElement(component) ? (
                component
            ) : (
                <RootModalContext.Consumer>
                    {(rootModalContext) => {
                        return React.cloneElement(component, {
                            rootModalContext,
                        })
                    }}
                </RootModalContext.Consumer>
            )}
        </Modal>
    )
}
modalWrapper.displayName = 'modalWrapper'
