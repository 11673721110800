import { actionTypes } from './constants.js'

const reducer = (
    state = { mediaData: {}, mediaMeta: {}, thumbnails: {} },
    action
) => {
    switch (action.type) {
        // Handle other actions here
        case actionTypes.GET_MEDIA_LIST:
            return {
                ...state,
                mediaIDs: action.payload,
            }
        /*case actionTypes.GET_MEDIA_DATA:
            return { ...state, mediaData: action.payload }*/
        case actionTypes.MY_MEDIA_DATA:
            return { ...state, mediaData: action.payload }
        case actionTypes.MY_MEDIA_META:
            return { ...state, mediaMeta: action.payload }
        case actionTypes.SET_ACTIVE_MEDIA:
            return {
                ...state,
                activeMedia: {
                    activeID: action.payload.id,
                    mediaMeta: action.payload.mediaMeta,
                    mediaData: action.payload.mediaData,
                    orientation: action.payload.mediaMeta.orientation,
                    type: action.payload.type,
                },
            }
        case actionTypes.UNSET_ACTIVE_MEDIA:
            return {
                ...state,
                activeMedia: null,
            }
        case actionTypes.UPDATE_NEW_ORIENTATION:
            return {
                ...state,
                activeMedia: {
                    ...state.activeMedia,
                    orientation: action.payload.orientation,
                },
            }
        case actionTypes.TOGGLE_METADATA_SHOWING: {
            let { mediaMeta } = state || {}
            const { mediaID } = action
            const media = (mediaMeta && mediaMeta[mediaID]) || null

            if (media) {
                media.metadataShowing = !media.metadataShowing
                return {
                    ...state,
                    mediaMeta: { ...mediaMeta, [mediaID]: media },
                }
            }

            return state
        }
        case actionTypes.GET_LAST_MEDIA_STORED:
            return {
                ...state,
                lastMedia: action.payload,
            }
        case actionTypes.SYNC_MEDIA_DATA_STORES:
            return {
                ...state,
                lastSync: action.payload.lastSync,
            }
        case actionTypes.SET_THUMBNAIL: {
            const thumbnails = {
                ...state.thumbnails,
                [action.payload.id]: action.payload.blob,
            }
            return {
                ...state,
                thumbnails,
            }
        }
        case actionTypes.UPDATE_MEDIA_META: {
            return {
                ...state,
                mediaMeta: {
                    ...state.mediaMeta,
                    [action.payload.id]: {
                        ...state.mediaMeta[action.payload.id],
                        status: action.payload.status,
                        data: action.payload.meta,
                    },
                },
            }
        }
        case actionTypes.UPDATE_MEDIA_DATA: {
            return {
                ...state,
                mediaData: {
                    ...state.mediaData,
                    [action.payload.id]: {
                        ...state.mediaData[action.payload.id],
                        status: action.payload.status,
                        data: action.payload.media,
                    },
                },
            }
        }
        default:
            return state
    }
}

export default reducer
