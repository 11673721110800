import { createStore, applyMiddleware, compose } from 'redux'

import thunk from 'redux-thunk'
import reducers from './reducers/index.js'

// enable reduxdevtools extension
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, compose(applyMiddleware(thunk)))

// Make it convenient to access the Global Store...
window.store = store

export default store
