import { actionTypes } from './constants.js'

export function getWebCam(getState) {
    const cameraState = getState().camera
    return cameraState && cameraState.camera
}

export default function cameraReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.CHANGE_CAMERA:
            return {
                ...state,
                currentCameraId: action.payload,
                settings:
                    state.currentCameraId === action.payload
                        ? state.settings
                        : null,
            }
        case actionTypes.SET_CAMERA_ACQUIRING:
            return { ...state, acquiring: action.payload }
        case actionTypes.SET_GLOBAL_STREAM_TRACK:
            return { ...state, globalStreamTrack: action.payload }
        case actionTypes.SET_CAMERA_LIST:
            return { ...state, cameraDevices: action.payload }
        case actionTypes.SET_CAMERA:
            return {
                ...state,
                camera: action.payload,
                settings: state.camera === action.payload ? state.settings : {},
            }
        case actionTypes.IS_CAMERA_ON:
            return {
                ...state,
                cameraOn: !JSON.parse(localStorage.getItem(action.payload)),
            }
        case actionTypes.SET_CAMERA_ON:
            localStorage.setItem(action.key, !action.payload)
            return { ...state, cameraOn: action.payload }
        case actionTypes.SET_CAMERA_TAKING_PHOTO:
            return { ...state, takingPicture: action.payload }
        case actionTypes.SET_CAMERA_TAKING_VIDEO:
            return { ...state, isRecording: action.payload }
        case actionTypes.SET_MYVID:
            return { ...state, myvid: action.payload }
        case actionTypes.SET_CAMERA_INITING:
            return { ...state, cameraIsIniting: action.payload }
        case actionTypes.SET_CAMERA_READY:
            return { ...state, cameraIsReady: action.payload }
        case actionTypes.CAMERA_VIDEO_CONNECTED:
            return { ...state, mediaStreamTrack: action.payload }
        case actionTypes.SET_CAMERA_SETTINGS:
            return { ...state, settings: action.payload }
        case actionTypes.SET_HUD_REF:
            return { ...state, hudRef: action.payload }
        case actionTypes.ADD_HUD_LAYER:
            return {
                ...state,
                hudLayers: {
                    ...state.hudLayers,
                    [action.payload.name]: action.payload.layer,
                },
            }
        case actionTypes.ADD_CAMERA: {
            return {
                ...state,
                cameras: {
                    ...state.cameras,
                    [action.payload.guid]: action.payload,
                },
            }
        }
        default:
            return state
    }
}
