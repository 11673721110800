import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'INIT_APP',
    'INITIALIZE_SETTINGS',
    'UPDATE_UI_VAL',
    'SET_UI_VALS',
    'SET_PICTURE_IN_PICTURE',
    'SET_FULL_SCREEN_ENABLED',
    'SET_GLOBAL',
    'GET_BROWSER',
    'SET_UI_LAYER_ACTIVE',
    'SET_UI_LAYER_ENABLED',
    'SET_UI_LAYER_SUPPRESSED',
    'SET_UI_COMPONENT_ACTIVE',
    'SET_EXECUTION_ENVIRONMENT',
    'SHOW_MODAL',
    'HIDE_MODAL',
    'ERROR',
    'REMOVE_ERROR',
    'ADD_MENU',
    'REMOVE_MENU',
    'UPDATE_BUILD_INFO',
    'PWA_INSTALL_READY',
    'PWA_INSTALL_NOT_READY',
    'SET_MAP_MODE_ENABLED',
    'SET_MAP_MODE',
    'SET_WINDOW_BOUNDS',
    'ENQUEUE_SNACKBAR',
    'CLOSE_SNACKBAR',
    'REMOVE_SNACKBAR',
    'SET_APP_CONTENT_EL',
])
