let serviceWorkerReadyResolve, serviceWorkerReadyReject
export let serviceWorkerReady

export default function registerServiceWorker() {
    serviceWorkerReady = new Promise((resolve, reject) => {
        serviceWorkerReadyResolve = resolve
        serviceWorkerReadyReject = reject
    })

    if (navigator.serviceWorker) {
        console.log(
            'service worker state:',
            navigator.serviceWorker?.controller?.state
        )

        navigator.serviceWorker.addEventListener('statechange', function (e) {
            console.log('service worker state:', e.target)
        })

        navigator.serviceWorker
            .register('./sw.js')
            .then(function (registration) {
                console.log('service worker registered')
                if (
                    window.performance
                        .getEntriesByType('navigation')
                        .map((nav) => nav.type)
                        .includes('reload')
                ) {
                    // the page was reloaded. So we should force the service worker to update
                    registration.update()
                }

                serviceWorkerReadyResolve(registration)
            })
            .catch(function (err) {
                console.error(err, 'Unable to register service worker.')
                serviceWorkerReadyReject(err)
            })
    }
}
