import { actionTypes } from './constants.js'

export default function netReducer(
    state = {
        publishedEventLayers: {},
        mediaData: {},
        mediaKeys: {},
        clients: {},
        streams: {},
        media: {},
        thumbnails: {},
    },
    action
) {
    switch (action.type) {
        case actionTypes.SET_ORIENTATION_SERVER:
            return { ...state, fbOrientationServer: action.payload }
        case actionTypes.ACQUIRING_ORIENTATION_SERVER:
            return { ...state, acquiringOrientationServer: action.payload }
        case actionTypes.SET_MY_FIREBASE_GLOBE_POSE_REFS:
            return { ...state, myGlobePoseRefs: action.payload }
        case actionTypes.SET_MY_FIREBASE_POSE_REFS:
            return { ...state, myPoseRefs: action.payload }
        case actionTypes.SET_MONITOR_XYZ:
            return { ...state, monitorXYZPoints: action.payload }
        case actionTypes.SET_XYZ_POINTS:
            return { ...state, xyzPoints: action.payload }
        case actionTypes.SET_ICE_SERVERS:
            return {
                ...state,
                settings: { ...state.settings, ...action.payload },
            }
        case actionTypes.SET_WEBRTC_SERVER:
            return { ...state, webrtcServer: action.payload }
        case actionTypes.SET_MY_PEERS_LIST:
            return { ...state, peers: { ...action.payload } }
        case actionTypes.PUBLISHED_EVENT_LAYERS:
            return { ...state, publishedEventLayers: { ...action.payload } }
        case actionTypes.USER_EVENT_LAYERS:
            return { ...state, userEventLayers: { ...action.payload } }
        case actionTypes.PEER_ORIENTATION_MONITORS:
            return { ...state, peerOrientationMonitors: { ...action.payload } }
        case actionTypes.PEER_ORIENTATIONS:
            return { ...state, peerOrientations: { ...action.payload } }
        case actionTypes.BROADCAST:
            return { ...state, broadcast: action.payload }
        case actionTypes.CLIENTS:
            return { ...state, clients: { ...action.payload } }
        case actionTypes.MEDIA_DATA:
            return { ...state, mediaData: { ...action.payload } }
        case actionTypes.MEDIA_KEYS:
            return { ...state, mediaKeys: { ...action.payload } }
        case actionTypes.STREAMS:
            return { ...state, streams: { ...action.payload } }
        case actionTypes.MEDIA: {
            console.log('media reducer: ', action)
            return { ...state, media: { ...action.payload } }
        }
        case actionTypes.SET_LAST_CONNECTED_CLIENT:
            return { ...state, lastConnectedClientID: action.payload }
        case actionTypes.SET_FOUR_CORNERS: {
            const peerBoxes = {
                ...state.peerBoxes,
                [action.payload.id]: action.payload.data,
            }
            return { ...state, peerBoxes }
        }
        case actionTypes.UPDATE_NET_STATE: {
            return { ...state, netState: action.payload }
        }
        case actionTypes.TAKE_PHOTO_REQUEST:
            return {
                ...state,
                takePhotoRequests: {
                    ...state.takePhotoRequests,
                    [action.payload]: true,
                },
            }
        case actionTypes.REMOVE_TAKE_PHOTO_REQUEST: {
            const takePhotoRequests = { ...state.takePhotoRequests }
            delete takePhotoRequests[action.payload]
            return {
                ...state,
                takePhotoRequests,
            }
        }
        case actionTypes.ADD_REMOTE_SCRIPT_PROMISE: {
            const { instanceID, scriptPromise } = action.payload
            return {
                ...state,
                remoteScriptPromises: {
                    ...state.remoteScriptPromises,
                    [instanceID]: scriptPromise,
                },
            }
        }
        case actionTypes.RESOLVE_REMOTE_SCRIPT_PROMISE: {
            const { instanceID, result } = action.payload
            const promise = state.remoteScriptPromises[instanceID]
            if (promise) {
                promise.resolve(result)
            }
            delete state.remoteScriptPromises[instanceID]
            return {
                ...state,
                remoteScriptPromises: {
                    ...state.remoteScriptPromises,
                },
            }
        }
        case actionTypes.REJECT_REMOTE_SCRIPT_PROMISE: {
            const { instanceID, result } = action.payload
            const promise = state.remoteScriptPromises[instanceID]
            if (promise) {
                promise.reject(result)
            }
            delete state.remoteScriptPromises[instanceID]
            return {
                ...state,
                remoteScriptPromises: {
                    ...state.remoteScriptPromises,
                },
            }
        }

        default:
            return state
    }
}
