import { keyMirror } from 'redfish-core/lib/utils/utils'

export const actionTypes = keyMirror([
    'SYNC_MEDIA',
    'SET_WEBRTC_SERVER',
    'SET_ICE_SERVERS',
    'SET_ORIENTATION_SERVER',
    'SET_ORIENTATION',
    'SET_MY_PEERS_LIST',
    'SET_MONITOR_XYZ',
    'SET_XYZ_POINTS',
    'SET_MY_FIREBASE_POSE_REFS',
    'ACQUIRING_ORIENTATION_SERVER',
    'PEER_ORIENTATION_MONITORS',
    'PEER_ORIENTATIONS',
    'BROADCAST',
    'CLIENTS',
    'MEDIA_DATA',
    'MEDIA_KEYS',
    'STREAMS',
    'MEDIA',
    'PUBLISHED_EVENT_LAYERS',
    'USER_EVENT_LAYERS',
    'SET_LAST_CONNECTED_CLIENT',
    'SET_FOUR_CORNERS',
    'INIT_NET_WORKER',
    'UPDATE_NET_STATE',
    'TAKE_PHOTO_REQUEST',
    'REMOVE_TAKE_PHOTO_REQUEST',
    'ADD_REMOTE_SCRIPT_PROMISE',
    'RESOLVE_REMOTE_SCRIPT_PROMISE',
    'REJECT_REMOTE_SCRIPT_PROMISE',
])
