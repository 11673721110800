import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createTheme({
    overrides: {
        MuiButton: {
            label: {
                color: '#000',
            },

            root: {
                margin: '5px',
                padding: '5px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },

            contained: {
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
        },
        MuiIconButton: {
            root: {
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
            colorPrimary: {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
            colorSecondary: {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
        },
        MuiSwitch: {
            switchBase: {
                backgroundColor: 'rgb(0, 0, 0, 0.125)', // Light green, aka #74d77f
            },
        },
        MuiCard: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.25)',
                color: '#FFFFFF',
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: '#FFFFFF',
                padding: '8px',
            },
        },
        MuiTreeItem: {
            root: {
                '&:focus > $content': {
                    backgroundColor: 'rgba(80,80,80,0.5)',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#007700',
        },
        secondary: {
            main: '#aa7000',
        },
        error: {
            main: '#cc3333',
        },
        text: {
            primary: '#cccccc',
            secondary: '#AAAAFF',
        },
        background: {
            default: '#303030',
        },
    },

    props: {
        // Name of the component ⚛️
        MuiButtonBase: {},
    },

    typography: {
        useNextVariants: true,
        h1: {
            fontSize: '48px',
            fontWeight: 'bold',
        },
    },
})

theme = responsiveFontSizes(theme)

const infoStyle = () => ({
    sectionLabel: {},
})

const peerDetailStyle = () => ({
    card: {
        maxWidth: 345,
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'scale-down',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    palette: {
        text: {
            primary: '#000',
        },
    },
})

const menuStyle = {
    noPadding: {
        minWidth: '100%',
        padding: '0 !important',
        margin: '0 0 0 0 !important',
        width: '100%',
        minHeight: '0 !important',
    },

    subMenu: {
        margin: '0 0 0 0 !important',
        padding: '0 !important',
        width: '100%',
        minWidth: '100%',
        minHeight: '0 !important',
    },

    subMenuSummary: {
        margin: '6px 0 6px 0',
    },

    subMenuIcon: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#aaa',
        fontSize: '1em',
    },
}

export { theme, infoStyle, peerDetailStyle, menuStyle }
