import { actionTypes } from './constants.js'
import { actionTypes as authActionTypes } from '../../auth/redux/constants.js'

export default function adminReducer(state = {}, action) {
    switch (action.type) {
        case authActionTypes.AUTH_NO_USER: {
            return { ...state, admin: null, admins: null, users: null }
        }
        case actionTypes.UPDATE_ADMIN: {
            action.payload.isSuperUser = action.payload.option === 'superuser'
            return { ...state, admin: action.payload }
        }
        case actionTypes.UPDATE_ADMIN_USERS: {
            return { ...state, users: action.payload }
        }
        case actionTypes.UPDATE_ADMIN_ADMINS: {
            return { ...state, admins: action.payload }
        }

        default:
            return state
    }
}
