import { initializeApp, setLogLevel } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import {
    child,
    get,
    getDatabase,
    off,
    onChildAdded,
    onDisconnect,
    onValue,
    orderByValue,
    push,
    query,
    ref,
    remove,
    serverTimestamp,
    set,
    startAt,
    update,
} from 'firebase/database' //need to import in order for database functionality to exist

import { getStorage } from 'firebase/storage' //need to import in order for storage functionality to exist

import { acequiaConfig, anyhazardConfig, rteConfig } from './firebaseConfig.js'

setLogLevel('VERBOSE')

let _FB

if (!_FB) {
    _FB = initializeApp(rteConfig)
    // fix bug where firebase kills itself for the rest of time.
    localStorage.removeItem('firebase:previous_websocket_failure')
}
window.FB = _FB
window.getDatabase = getDatabase

console.log('FB: ', getDatabase(_FB))

function getFB() {
    return _FB
}

function getFBDatabase() {
    return getDatabase(getFB())
}

var fbAnyhazard
function getAnyHazardFB() {
    if (!fbAnyhazard) {
        fbAnyhazard = initializeApp(anyhazardConfig, 'anyhazard')
        // fix bug where firebase kills itself for the rest of time.
        localStorage.removeItem('firebase:previous_websocket_failure')
    }
    window.fbAnyhazard = getDatabase(fbAnyhazard)
    return fbAnyhazard
}

var fbAcequiaApp
function getAcequiaFBApp() {
    if (!fbAcequiaApp) {
        fbAcequiaApp = initializeApp(acequiaConfig, 'acequia')
        // fix bug where firebase kills itself for the rest of time.
        localStorage.removeItem('firebase:previous_websocket_failure')
    }
    return fbAcequiaApp
}

var fbAcequiaDatabase
function getAcequiaFBDatabase() {
    if (!fbAcequiaDatabase) {
        fbAcequiaDatabase = getDatabase(getAcequiaFBApp())
    }
    window.fbAcequia = fbAcequiaDatabase
    return fbAcequiaDatabase
}

var fbAcequiaStorage
function getAcequiaFBStorage() {
    if (!fbAcequiaStorage) {
        fbAcequiaStorage = getStorage(getAcequiaFBApp())
    }
    return fbAcequiaStorage
}

function getFBRefFromURL(url) {
    let url2
    let fb
    if (url.indexOf('https://anyhazard.firebaseio.com') >= 0) {
        url2 = url.split('https://anyhazard.firebaseio.com')[1]
        fb = getDatabase(getAnyHazardFB())
    } else if (url.indexOf('https://livetexture.firebaseio.com') >= 0) {
        url2 = url.split('https://livetexture.firebaseio.com')[1]
        fb = getDatabase(getFB())
    } else if (url.indexOf('https://acequia.firebaseio.com') >= 0) {
        url2 = url.split('https://acequia.firebaseio.com')[1]
        fb = getDatabase(getAcequiaFBApp())
    }
    if (!url2) {
        console.warn('Failed to find suitable firebase location')
        return
    }

    let fbRef = ref(fb, url2)
    return fbRef
}

function checkFirebaseURL(url) {
    if (
        url.indexOf('https://anyhazard.firebaseio.com') >= 0 ||
        url.indexOf('https://livetexture.firebaseio.com') >= 0
    ) {
        return true
    }

    return false
}

function isFirebaseURL(url) {
    if (url && url.indexOf('.json') <= 0 && url.indexOf('firebaseio.com/') >= 0)
        return true
    return false
}

function getAuthRef() {
    return getAuth(getFB())
}

function logOut() {
    return getAuthRef().signOut()
}

const ServerTimeStamp = serverTimestamp()

window.firebase = {
    getFB,
    getFBDatabase,
    logOut,
    getAuthRef,
    getAcequiaFBApp,
    isFirebaseURL,
    getAnyHazardFB,
    getFBRefFromURL,
    ServerTimeStamp,
    checkFirebaseURL,
    getDatabase,
    ref,
    child,
    get,
    off,
    set,
    onDisconnect,
    onChildAdded,
    onValue,
    remove,
    update,
    startAt,
    orderByValue,
    query,
}

export {
    getFB,
    getFBDatabase,
    logOut,
    getAuthRef,
    getAcequiaFBApp,
    getAcequiaFBDatabase,
    getAcequiaFBStorage,
    isFirebaseURL,
    getAnyHazardFB,
    getFBRefFromURL,
    ServerTimeStamp,
    checkFirebaseURL,
    ref,
    child,
    get,
    off,
    set,
    onDisconnect,
    onChildAdded,
    onValue,
    remove,
    update,
    startAt,
    orderByValue,
    push,
    query,
    serverTimestamp,
}
