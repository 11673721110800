import { keyMirror } from 'redfish-core/lib/utils/utils'

export const actionTypes = keyMirror([
    'SET_GLOBAL_STREAM_TRACK',
    'SET_CAMERA',
    'CHANGE_CAMERA',
    'SET_CAMERA_ACQUIRING',
    'IS_CAMERA_ON',
    'SET_CAMERA_ON',
    'SET_CAMERA_INITING',
    'SET_CAMERA_READY',
    'SET_CAMERA_TAKING_PHOTO',
    'SET_CAMERA_TAKING_VIDEO',
    'CAMERA_VIDEO_CONNECTED',
    'SET_MYVID',
    'SET_CAMERA_SETTINGS',
    'SET_CAMERA_LIST',
    'SET_CAMERA_UPDATING_SETTINGS',
    'SET_HUD_REF',
    'ADD_HUD_LAYER',
    'ADD_CAMERA',
])
