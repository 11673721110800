import { keyMirror } from 'redfish-core/lib/utils/utils'
export const actionTypes = keyMirror([
    'MAPS',
    'MODES',
    'COLORS',
    'LABELS',
    'LOADED',
    'LOADING',
    'CAMERAS',
    'ELEMENTS',
    'INCIDENT',
    'GEO_POINTS',
    'TIE_POINTS',
    'FULL_STATE',
    'PANE_ORDER',
    'ADD_CAMERAS',
    'ANNOTATIONS',
    'INCIDENT_ID',
    'MAP_CENTERS',
    'SEARCH_TERM',
    'STAR_POINTS',
    'TIME_STAMPS',
    'ACTIVE_POINT',
    'CALIBRATIONS',
    'INCIDENT_IDS',
    'ACTIVE_VERTEX',
    'PLANET_POINTS',
    'GENERATING_MAP',
    'ADD_TIE_POINTS',
    'INCIDENT_NAMES',
    'LAT_LON_POINTS',
    'ANNOTATING_MODE',
    'HOT_CURSOR_JSON',
    'ANNOTATION_MODE',
    'ANNOTATION_TYPE',
    'ANNOTATION_LAYER',
    'ACTIVE_TIE_POINT',
    'ACTIVE_ANNOTATION',
    'ACTIVE_CAMERA_IDS',
    'CURRENT_TIE_POINTS',
    'GOT_SEARCH_RESPONSE',
    'CURRENT_ACTIVE_INDEX',
    'CURRENT_CALIBRATIONS',
    'INIT_SEARCH_RESPONSE',
])
