import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'REMOVE_GLOBAL_MEDIA_STORE_ITEM',
    'REMOVE_GLOBAL_MEDIA_DATA_STORE_PEER',
    'UPDATE_GLOBAL_MEDIA_STORE_PEERS_INDEX',
    'SYNC_MEDIA_DATA_STORES',
    'SET_GLOBAL_MEDIA_STORE_ITEM',
    'STORE_MEDIA_WITHOUT_BLOB',
    'STORE_FILE_BLOB',
    'MIGRATE_MEDIA',
    'GET_MEDIA_TAGS',
    'ADD_MEDIA_TAG',
    'GET_MEDIA_LIST',
    'DOWNLOAD_MEDIA',
    'REMOVE_MEDIA',
    'SET_ACTIVE_MEDIA',
    'STORE_PHOTO',
    'GET_PHOTO_FROM_URL',
    'RECEIVED_PHOTO_FROM_URL',
    'UPDATE_MEDIA_META',
    'UPDATE_MEDIA_DATA',
    'RESET_ORIENTATION_FROM_EXIF',
    'UPDATE_NEW_ORIENTATION',
    'GET_MEDIA',
    'TOGGLE_METADATA_SHOWING',
    'UNSET_ACTIVE_MEDIA',
    'GET_LAST_MEDIA_STORED',
    //'GET_MEDIA_DATA',
    'MY_MEDIA_DATA',
    'MY_MEDIA_META',
    'SET_THUMBNAIL',
])
