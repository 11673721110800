import React, { Component } from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { modalWrapper } from './modals/ModalUtils.js'

class DeviceBrowserCompatibility extends Component {
    isOniPhoneNotSafari() {
        let userAgent = this.props.browser
        return userAgent.os.name === 'iOS' &&
            userAgent.browser.name !== 'Safari'
            ? true
            : false
    }

    constructDiv() {
        let blurb
        if (this.isOniPhoneNotSafari()) {
            blurb = (
                <div>
                    <Typography variant="h1">Welcome!</Typography>
                    <Typography variant="body1">
                        Your device or browser is not currently supported.
                    </Typography>
                    <Typography variant="body1">
                        For the best iPhone user experience, please switch to
                        Safari.
                    </Typography>
                    <Typography variant="body1">
                        Or, continue knowing that things may not quite work
                        correctly.
                    </Typography>
                </div>
            )
        } else {
            blurb = (
                <div>
                    <Typography variant="h1">Welcome!</Typography>
                    <Typography variant="body1">
                        Your device or browser is not currently supported.
                    </Typography>
                    <Typography variant="body1">
                        For the best user experience, please switch to Chrome.
                    </Typography>
                    <Typography variant="body1">
                        Or, continue knowing that things may not quite work
                        correctly.
                    </Typography>
                </div>
            )
        }
        return (
            <Paper>
                {blurb}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (this.props.rootModalContext.onRequestClose) {
                            this.props.rootModalContext.onRequestClose()
                        }
                    }}
                >
                    ok
                </Button>
            </Paper>
        )
    }

    render() {
        return this.constructDiv()
    }
}

function mapStateToProps(state) {
    return {
        browser: state.ui.browser,
    }
}

function mapDispatchToProps() {
    return {}
}

const DeviceBrowserCompatibilityComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceBrowserCompatibility)

const DeviceBrowserCompatibilityModal = modalWrapper(
    <DeviceBrowserCompatibilityComponent />
)

export {
    DeviceBrowserCompatibility,
    DeviceBrowserCompatibilityComponent,
    DeviceBrowserCompatibilityModal,
}
