import { actionTypes as uiActionTypes } from '../constants.js'
import { actionTypes as authActionTypes } from '../../auth/redux/constants.js'

const IGNORE_ACTION_LIST = [
    'CAMERAS',
    'POSITION',
    'ANNOTATIONS',
    'PEER_ORIENTATIONS',
    'SET_MY_FIREBASE_POSE_REFS',
    'SET_XYZ_POINTS',
    'SET_MY_PEERS_LIST',
    'MY_MEDIA_DATA',
    'MY_MEDIA_META',
    'TICK',
    'ADVANCE_TIME',
    'REMOVE_SNACKBAR',
    'LAYER_DATA',
    'LAYER_LOADED',
    'LAYER_LOADING',
    'LAYER_META',
    'LAYER_STYLE',
    'UPDATE_NET_STATE',
    'RUN_SCRIPT_BY_ID_CALL',
    'RUN_SCRIPT_BY_ID_RESULT',
    'RUN_SCRIPT_RESULT_PENDING',
    'LAYER_CHANGE_VALUE',
    'SET_THUMBNAIL',
    'SET_SCRIPTS',
    'SET_SCRIPT_FIELD',
    'TIE_POINTS',
    'TIME_STAMPS',
]

export default (state = {}, action) => {
    if (!IGNORE_ACTION_LIST.includes(action.type)) {
        console.log(action, 'REDUX DEBUG')
    }

    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            console.log('Location Change', {
                page_path: action.payload.location.pathname,
            })
            return state
        }
        case uiActionTypes.ERROR: {
            console.log('event', 'error', {
                event_label: action.payload.componentName,
            })
            return state
        }
        case uiActionTypes.REMOVE_ERROR:
            console.log('event', 'show_menu', {
                event_label: action.payload.componentName,
            })
            return state
        case uiActionTypes.SET_UI_COMPONENT_ACTIVE:
            console.log('event', 'show_menu', {
                event_label: action.payload.componentName,
            })
            return state
        case uiActionTypes.SHOW_MODAL:
            console.log('event', 'show_modal', {
                event_label: action.payload.name,
            })
            return state
        case uiActionTypes.HIDE_MODAL:
            console.log('event', 'hide_modal', {})
            return state
        case authActionTypes.AUTH_STATECHANGE: {
            const { user } = action.payload
            if (user && user.uid) {
                console.log('event', 'user_signed_in', {})
            } else {
                console.log('event', 'user_not_signed_in', {})
            }
            return state
        }
        default:
            return state
    }
}
