import { actionTypes } from './constants.js'

export default function locatorReducer(state = {}, action) {
    switch (action.type) {
        case actionTypes.LOADED:
            return { ...state, loaded: action.payload }
        case actionTypes.LOADING:
            return { ...state, loading: action.payload }
        case actionTypes.FULL_STATE:
            return { ...state, ...action.payload }
        default:
            return state
    }
}
