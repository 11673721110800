/* eslint-disable no-eval*/
import LocalForage from 'localforage/dist/localforage.js'
import { generateGUID } from 'redfish-core/lib/auth/guid.js'

export var types = {
    ANY: 'any',
    DATA: 'data',
    SCRIPT: 'script',
    GEOJSON: 'geojson',
    RASTERLAYER: 'rasterlayer',
    OBSERVERTILES: 'observertiles',
    RASTERTILELAYER: 'rastertilelayer',
    PROGRESSIONLAYER: 'progressionlayer',
    ORTHOPEERLAYER: 'orthopeerlayer',
}

export var typeReverseMap = {}
Object.keys(types).forEach((key) => {
    typeReverseMap[types[key]] = key
})

export var typeDisplayName = {
    ANY: 'Any Layer',
    DATA: 'Data Layer',
    SCRIPT: 'Script Layer',
    GEOJSON: 'geoJSON Layer',
    RASTERLAYER: 'Raster Layer',
    OBSERVERTILES: 'Observer Tiles',
    RASTERTILELAYER: 'Raster Tile Layer',
    PROGRESSIONLAYER: 'Progression Layer',
    ORTHOPEERLAYER: 'Orthopeer Layer',
}

export function lookupDisplay(type) {
    return typeDisplayName[typeReverseMap[type]]
}

const layerStore = LocalForage.createInstance({
    name: 'layerStore',
})

const layerCache = LocalForage.createInstance({
    name: 'layerCache',
})

export function getLayer(guid) {
    return layerStore.getItem(guid)
}

export async function getLayersByType(type = types.ANY) {
    var entries = {}
    await layerStore.iterate((value) => {
        if (!value || !value.type) {
            console.error(`Got a bad layer: ${JSON.stringify(value)}`, value)
            return
        }
        if (value.type === type || type === types.ANY) {
            entries[value.guid] = value
        }
    })
    return entries
}

export async function getCachedData(url) {
    //  JBT yikes this is not going to scale...

    console.log('looking for cached data for: ', url)
    var result = await layerStore.iterate((value) => {
        console.log('checking url: ', value.url, value.cacheID)
        if (value.url === url && value.cacheID) {
            return layerCache.getItem(value.cacheID)
        }
    })

    console.log('got result: ', result)
    return result
}

export async function getCachedDataFromCacheID(cacheID) {
    var result = await layerCache.getItem(cacheID).then((value) => {
        return value
    })
    return result
}

export async function setCachedData(cacheID, dataToCache) {
    if (cacheID && dataToCache) {
        await layerCache.setItem(cacheID, dataToCache)
    }
}

function dataException(message) {
    this.name = 'dataException'
    this.message = message
}

export function setLayer(layer) {
    return layerStore.setItem(layer.guid, layer)
}

export async function setData(type, url, dataToCache = null, options = {}) {
    if (!type) {
        throw dataException('setData needs a type')
    }
    /*if (!url) {
        throw dataException('setData needs a url, or a file')
    }*/

    let guid = generateGUID()
    var storageData = {
        guid,
        name: 'not yet named_' + Math.random(),
        type,
        url,
        active: true,
        visible: true, // TODO: implement this. It will need to go through redux.
        opacity: 1.0, // TODO. implement this too.
        lastUpdate: new Date().getTime(),
    }
    storageData = Object.assign(storageData, options)

    if (dataToCache) {
        storageData.cacheID = guid
        await layerCache.setItem(guid, dataToCache)
    }
    return layerStore.setItem(guid, storageData)
}

export function updateValueInStore(guid, key, val) {
    return new Promise((resolve, reject) => {
        layerStore
            .getItem(guid)
            .then((value) => {
                if (value) {
                    if (value[key] !== val) {
                        value[key] = val
                        return layerStore
                            .setItem(guid, value)
                            .then((value) => resolve(value))
                            .catch((error) => reject(error))
                    } else {
                        resolve(value)
                    }
                } else {
                    console.error('did not find value for guid: ', guid)
                    return reject('did not find value for guid: ', guid)
                }
            })
            .catch((error) => {
                console.error('got error: ', error)
                reject(error)
            })
    })
}

export function updateLayerInStore(guid, layer) {
    return new Promise((resolve, reject) => {
        if (guid && layer) {
            layerStore
                .setItem(guid, layer)
                .then((value) => {
                    console.log(
                        'Layer successfully set in indexDB' +
                            JSON.stringify(value),
                        guid
                    )
                    resolve(value)
                })
                .catch((err) => {
                    reject(err)
                })
        } else {
            reject('guid or layer is undefined')
        }
    })
}

export async function removeData(guid) {
    var goners = []
    var promises = []
    await layerStore.iterate((value, key) => {
        if (key === guid) {
            goners.push(key)
            layerCache.removeItem(key)
        }
    })
    for (var i = 0; i < goners.length; i++) {
        promises.push(layerStore.removeItem(goners[i]))
    }
    return Promise.all(promises)
}

/**
 * This will throw an error something does not work
 */
export async function testLayerStoreFunctionality() {
    let initialKeys = await layerStore.keys()
    let initialKeysCache = await layerCache.keys()
    // add
    await setData(types.GEOJSON, 'fluffy', 'test')
    let keys = await layerStore.keys()
    if (keys.length - initialKeys.length !== 1) {
        throw dataException('setData failed')
    }
    let keysCache = await layerCache.keys()
    if (keysCache.length - initialKeysCache.length !== 1) {
        throw dataException('setData of cache failed')
    }
    // read
    let cachedData = await getCachedData('fluffy')
    if (cachedData !== 'test') {
        throw dataException('cached data Broken')
    }
    // remove
    await removeData('fluffy')
    let keys2 = await layerStore.keys()
    if (keys2.length !== initialKeys.length) {
        throw dataException('removeData Failed')
    }
    let keysCache2 = await layerCache.keys()
    if (keysCache2.length !== initialKeysCache.length) {
        throw dataException('remove of cache failed')
    }

    //
    // add some real test data for the other things to mess with
    await setData(
        types.GEOJSON,
        'http://node.redfish.com/Documents/WallowArea/wallow_fireHistory_4.geojson'
    )
}

window.testLayerStoreFunctionality = testLayerStoreFunctionality
window.getLayersByType = getLayersByType
