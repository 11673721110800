import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'RECEIVE_FIREBASE_REFS',
    'RECEIVE_MY_LAYER_PERMISSIONS',
    'GET_NEW_LAYER_REF',
    'RECEIVE_DEVICE_SETTINGS',
    'TASK_SUBMITTED',
    'TASK_COMPLETED',
    'TASK_ERRORED',
    'UPDATE_BASEURL',
    'RECEIVE_TOKENS',
    'UPDATE_TOKEN',
    'SET_USER_READY',
])
