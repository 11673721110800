import { actionTypes } from './constants.js'

function handleGetLayerList(state, action) {
    console.log('get layer list reducer called')
    return {
        ...state,
        layerList: { ...action.payload },
    }
}

function handleLayerChangeValue(state, action) {
    let guid = action.payload.guid
    let key = action.payload.key
    let val = action.payload.val
    let currList = { ...state.layerList }
    let lay = currList[guid]
    if (!lay) {
        console.warn('fail. this should not happen')
        return state
    }
    if (key == 'visible' && val === true && !lay.visible) {
        lay.loaded = false
    }
    if (action.type === actionTypes.LAYER_CHANGE_VALUE) {
        lay = { ...lay, [key]: val, updates: (lay.updates || 0) + 1 }
    } else if (action.type === actionTypes.LAYER_CHANGE_VALUE_SAVED) {
        lay = { ...lay, updates: (lay.updates || 0) - 1 }
    }
    currList[guid] = lay
    return {
        ...state,
        layerList: currList,
    }
}

function handleLayerRemove(state, action) {
    let url3 = action.payload.url
    let currList3 = { ...state.layerList }
    delete currList3[url3]
    return {
        ...state,
        layerList: currList3,
    }
}

function handleLayerError(state, action) {
    console.error('layer error called')
    const layerList = {
        ...state.layerList,
    }

    const layer = layerList[action.payload.guid]

    console.error(layer, 'got layer')

    if (layer) {
        layer.error = action.payload.error
    }

    return {
        ...state,
        layerList,
    }
}

function handleLayerLoadedState(state, guid, loaded) {
    const layerList = {
        ...state.layerList,
    }
    const layer = layerList[guid]
    if (layer) {
        layer.loaded = loaded
    }
    return {
        ...state,
        layerList,
    }
}

export default function geoReducer(
    state = {
        imagePlaneVisibility: true,
        geoPointVisibility: true,
        frustumVisibility: true,
        labelVisibility: true,
        uvRayVisibility: false,
        flyKeysEnabled: true,
        activeEventID: 'default',
        keysEnabled: true,
        pageIDs: [],
        fading: false,
        fade: false,
        events: {
            default: {
                annotationLayers: {},
                textureTargets: {},
                textureLights: {},
                annotations: {},
                shortcuts: {},
                tiePoints: {},
                geoPoints: {},
                markers: {},
                uvRays: {},
                bounds: {},
                uvs: {},
            },
        },
    },
    action
) {
    switch (action.type) {
        case actionTypes.EVENTS:
            return { ...state, events: { ...action.payload } }
        case actionTypes.POSITION:
            return { ...state, location: action.payload }
        case actionTypes.POSITION_UNDEFINED:
            return { ...state, location: null }
        case actionTypes.GET_LAYER_LIST:
            return handleGetLayerList(state, action)
        case actionTypes.LAYER_CHANGE_VALUE:
            return handleLayerChangeValue(state, action)
        case actionTypes.LAYER_CHANGE_VALUE_SAVED:
            return handleLayerChangeValue(state, action)
        case actionTypes.REMOVE_LAYER:
            return handleLayerRemove(state, action)
        case actionTypes.LAYER_ERROR:
            return handleLayerError(state, action)
        case actionTypes.LAYERS:
            return { ...state, layers: [...action.payload] }
        case actionTypes.FADE:
            return { ...state, fade: action.payload }
        case actionTypes.FADING:
            return { ...state, fading: action.payload }
        case actionTypes.SET_MAP:
            return { ...state, map: action.payload }
        case actionTypes.PAGE_IDS:
            return { ...state, pageIDs: action.payload }
        case actionTypes.SAVED_EVENTS:
            return { ...state, savedEvents: action.payload }
        case actionTypes.KEYS_ENABLED:
            return { ...state, keysEnabled: action.payload }
        case actionTypes.EVENT_LOADING:
            return { ...state, eventLoading: action.payload }
        case actionTypes.GEOPOINT_MODE:
            return { ...state, geoPointMode: action.payload }
        case actionTypes.ACTIVE_PAGE_ID:
            return { ...state, activePageID: action.payload }
        case actionTypes.ACTIVE_EVENT_ID:
            return { ...state, activeEventID: action.payload }
        case actionTypes.UVRAY_VISIBILITY:
            return { ...state, uvRayVisibility: action.payload }
        case actionTypes.LABEL_VISIBILITY:
            return { ...state, labelVisibility: action.payload }
        case actionTypes.FLY_KEYS_ENABLED:
            return { ...state, flyKeysEnabled: action.payload }
        case actionTypes.GEOPOINT_VISIBILITY:
            return { ...state, geoPointVisibility: action.payload }
        case actionTypes.KEY_INFO_MODAL_OPEN:
            return { ...state, keyInfoModalOpen: action.payload }
        case actionTypes.PULL_HIGH_RESOLUTION:
            return { ...state, pullHighRes: action.payload }
        case actionTypes.IMAGE_PLANE_VISIBILITY:
            return { ...state, imagePlaneVisibility: action.payload }
        case actionTypes.FRUSTUM_VISIBILITY:
            return { ...state, frustumVisibility: action.payload }
        case actionTypes.TEXTURELIGHT_HUD_MODAL_OPEN:
            return { ...state, textureLightHUDModalOpen: action.payload }
        case actionTypes.ANNOTATION_MODAL_OPEN:
            return { ...state, annotationModalOpen: action.payload }
        case actionTypes.FITTING_MODAL_OPEN:
            return { ...state, fittingModalOpen: action.payload }
        case actionTypes.GET_MAP_SELECTED_STYLE:
            return { ...state, mapSelectedStyle: action.payload }
        case actionTypes.SET_MAP_SELECTED_STYLE:
            return { ...state, mapSelectedStyle: action.payload }
        case actionTypes.SET_GEO_JSON_LAYER_MANAGER:
            return { ...state, geoJSONLayerManager: action.payload }
        case actionTypes.ADD_LAYER_INSTANCE: {
            const { type, guid, instance } = action.payload
            if (guid == undefined) return state
            let layerInstances = state.layerInstances || {}
            let { [type]: typeInstances } = layerInstances
            if (!typeInstances) {
                typeInstances = {}
                layerInstances[type] = typeInstances
            } else {
                typeInstances = { ...typeInstances }
            }
            typeInstances[guid] = instance
            layerInstances = { ...layerInstances, [type]: typeInstances }
            return { ...state, layerInstances }
        }
        case actionTypes.REMOVE_LAYER_INSTANCE: {
            const { type, guid } = action.payload
            let layerInstances = state.layerInstances || {}
            let { [type]: typeInstances } = layerInstances
            if (!typeInstances) {
                typeInstances = {}
                layerInstances[type] = typeInstances
            }
            let { [guid]: instanceToRemove, ...restOfTypeInstances } =
                typeInstances

            layerInstances = { ...layerInstances, [type]: restOfTypeInstances }
            return { ...state, layerInstances }
        }
        case actionTypes.LAYER_LOADED: {
            const guid = action.payload.guid
            //console.log('layer loaded called')
            return handleLayerLoadedState(state, guid, true)
        }
        case actionTypes.LAYER_LOADING: {
            const guid = action.payload.guid
            //console.log('layer loading called')
            return handleLayerLoadedState(state, guid, false)
        }
        case actionTypes.SAVED_EVENT_PERMISSION: {
            const layerGUID = action.payload.guid
            const savedEventsPermissions = { ...state.savedEventsPermissions }
            const currentPermissions = savedEventsPermissions[layerGUID] || {}

            const tokens = action.payload.permission.tokens
                ? action.payload.permission.tokens
                : currentPermissions.tokens || {}

            savedEventsPermissions[layerGUID] = {
                ...currentPermissions,
                ...action.payload.permission,
                tokens,
            }

            return { ...state, savedEventsPermissions }
        }
        default:
            return state
    }
}
