import { keyMirror } from 'redfish-core/lib/utils/utils.js'

export const actionTypes = keyMirror([
    'LOADED',
    'LOADING',
    'LAYER_UI',
    'LAYER_DATA',
    'LAYER_META',
    'LAYER_STYLE',
    'ACTIVE_LAYER',
    'DATA_REQUESTS',
    'MAP_LAYER_IDS',
    'TIE_POINT_LAYER',
    'DELETE_LAYER_META',
])
